import React from "react";
import ReactApexChart from "react-apexcharts";

const ApexBarChart = ({ data }) => {
    // Extract labels and values from the data
    const labels = data.map((item) => item[0]);
    const values1 = data.map((item) => item[1]);
    const values2 = data.map((item) => item[2]);

    const options = {
        chart: {
            type: "bar",
            height: 350,
            stacked: true,
            toolbar: {
                show: true,
            },
            zoom: {
                enabled: true,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 0,
                dataLabels: {
                    total: {
                        enabled: true,
                        style: {
                            fontSize: "13px",
                            fontWeight: 900,
                        },
                    },
                },
            },
        },
        xaxis: {
            categories: labels,
        },
        legend: {
            position: "right",
            offsetY: 40,
        },
        fill: {
            opacity: 1,
        },
        series: [
            {
                name: "FREE",
                data: values1,
            },
            {
                name: "UPI",
                data: values2,
            },
        ],
        colors: ['#730000', '#A37E36'],
    };

    // Check the screen width and modify chart configuration for mobile
    if (window.innerWidth <= 768) {
        options.chart.type = "bar"; // Change to horizontal bar chart
        options.plotOptions.bar.horizontal = true; // Make bars horizontal
    }

    return (
        <div id="chart">
            <ReactApexChart options={options} series={options.series} type="bar" height={350} />
        </div>
    );
};

export default ApexBarChart;
