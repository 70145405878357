import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getAdminToken } from '../../Helper/Token';
import { getOrderList } from '../../Api';
import { Box, Stack } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { formatNumberWithCommas } from '../../Helper/validation';
import { SuperBalls } from '@uiball/loaders';

function Users() {

    const dates = [];
    const startDate = new Date('2023-09-30T18:30:00.000Z');
    const endDate = new Date('2023-10-01T18:30:00.000Z');
    const oneDay = 24 * 60 * 60 * 1000;

    for (let i = 1; i <= 23; i++) {
        const range = {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
        };
        dates.push(range);

        // Move to the next day
        startDate.setTime(startDate.getTime() + oneDay);
        endDate.setTime(endDate.getTime() + oneDay);
    }

    console.log(dates);


    const columns = [
        { field: 'id', headerName: 'Sr. No.', width: 70 },
        { field: 'date', headerName: 'Date', width: 180, },
        { field: 'totalOrder', headerName: 'Total Orders', width: 200, },
        { field: 'totalVisitors', headerName: 'Total Visitors', width: 150, },
        { field: 'grandTotal', headerName: 'Total Sale', width: 120, },
    ];


    const [rows, setRows] = useState([]);
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    };

    const fetchOrderList = async () => {
        try {
            getAdminToken();
            const currentDate = new Date();
            let orderResults = [];

            for (let i = 0; i < dates.length; i++) {
                const date = dates[i];

                const startDate = new Date(date.startDate);

                if (startDate > currentDate) {
                    break;
                }

                const res = await axios.get(`${getOrderList}?dateStart=${date.startDate}&dateEnd=${date.endDate}`);
                const resp = await axios.get(`${getOrderList}?dateStart=${date.startDate}&dateEnd=${date.endDate}&limit=${res.data.result.totalDocs || ''}`);

                let total = 0;
                let tickets = 0;
                resp.data.result.docs.forEach((ele) => {
                    total += ele.OrderTotal;
                    tickets += ele.PurchasedTickets;
                });

                const isoDate = new Date(date.startDate);
                orderResults.push({
                    id: i + 1,
                    date: isoDate.toLocaleString('en-US', options),
                    totalOrder: resp.data.result.totalDocs,
                    totalVisitors: tickets,
                    grandTotal: formatNumberWithCommas(total),
                });
            }

            setRows(orderResults);
        } catch (error) {
            console.error("Error fetching order list:", error);
        }
    };

    useEffect(() => {
        fetchOrderList();
    }, []);

    return (
        <>
            <section className='adminDashboard'>
                <div className="dashboard-right-header fr05s-usr-quotsn05s">
                    <div className="left-text">
                        <h4>Each Day Sale</h4>
                        <p className="m-0">Here is each day sale</p>
                    </div>
                </div>

                <div className="ticketsContainer">
                    <Box sx={{ width: '100%' }}>
                        <DataGrid
                            autoHeight {...rows}
                            rows={rows}
                            columns={columns}
                            disableSelectionOnClick
                            pagination={false}
                            components={{
                                NoRowsOverlay: () => (
                                    <Stack height="100%" alignItems="center" justifyContent="center">
                                        <SuperBalls size={45} speed={1.4} color="var(--primary)" />
                                    </Stack>
                                )
                            }}
                        />
                    </Box>
                </div>
            </section>
        </>
    );
}
export default Users