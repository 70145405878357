import React, { useEffect } from 'react'
import './Popup.css'
import { Col, Modal, Pagination, Row, Switch, Text, useModal } from '@nextui-org/react';
import { Button } from '@material-ui/core';
import { NavLink } from 'react-bootstrap';
import { useState } from 'react';
import axios from 'axios';
import { getCmsList } from '../../Api';
import { Link, useNavigate } from 'react-router-dom';

function Popup() {

    const { setVisible: countDownModalVisible, bindings: countDownBindings } = useModal();
    const [cms, setCms] = useState({});
    const navigate = useNavigate()

    const fetchCms = async () => {
        try {
            const response = await axios.get(getCmsList);
            const cmsData = response.data.result.docs.find((ele) => ele.flag === 1);

            if (cmsData) {
                setCms(cmsData);
                countDownModalVisible(true);
            }
        } catch (error) {
            console.error('Error fetching CMS data:', error);
        }

        // countDownModalVisible(true);
    };
    useEffect(() => {
        fetchCms();
    }, []);

    // Time Count Down
    const endDate = new Date(cms.EndsTime).getTime();
    const [timeLeft, setTimeLeft] = useState({})
    function updateCountdown() {
        const now = new Date().getTime();
        const timeLeft = endDate - now;

        if (timeLeft <= 0) {
            countDownModalVisible(false);
            return;
        }

        const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

        setTimeLeft({
            days: days,
            hours: hours,
            minutes: minutes,
            seconds: seconds
        })
    }
    setInterval(() => {
        if (cms.EndsTime) {
            updateCountdown()
        }
    }, 1000);

    console.log(timeLeft, cms);
    return (
        <>
            <Modal className='countDownPopup' preventClose size="5xl" scroll width="800px" closeButton aria-labelledby="modal-title" aria-describedby="modal-description" {...countDownBindings} >
                <Modal.Body>
                    <div className='countDoenModel'>
                        <div className='heading'>
                            {cms.Title && <h1>{cms.Title}</h1>}
                            {cms?.Discription && <p>
                                {cms?.Discription?.split('\n').map((line, index) => (
                                    <React.Fragment key={index}>
                                        {line}
                                        <br />
                                    </React.Fragment>
                                ))}
                            </p>}

                            {
                                cms.EndsTime ?
                                    timeLeft && timeLeft.seconds !== "" ?
                                        <div className="countdown-timer">
                                            {Object.keys(timeLeft).map((unit) => (
                                                <div className="flip" key={unit}>
                                                    <div className="top">{`${timeLeft[unit] < 10 ? `0${timeLeft[unit]}` : timeLeft[unit]}`}<span>{unit}</span></div>
                                                </div>
                                            ))}
                                        </div> : <div class="loader"></div> : ""
                            }
                            {cms.AdditionalInfo && <p>{cms.AdditionalInfo}</p>}

                            {
                                cms.ButtonTitle && cms.ButtonLink &&
                                <div className='button'>
                                    <Link className="common-btn cst-font-size" to={cms.ButtonLink} onClick={() => navigate(cms.ButtonLink)} ><span>{cms.ButtonTitle}</span></Link>
                                </div>
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal >
        </>
    )
}

export default Popup