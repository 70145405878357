import React, { useState } from 'react'
import "./AdminLogin.css"
import Form from 'react-bootstrap/Form';
import Button from '@mui/material/Button';
// import { NavLink } from 'react-router-dom';
import { Link, NavLink } from 'react-router-dom';
import axios from 'axios';
import { getAdminDetail, getAdminDetails, getAdminLogin, getUserLogin } from '../../Api';
import { Crypt } from 'hybrid-crypto-js';
import { AiOutlineEye } from 'react-icons/ai'
import { AiOutlineEyeInvisible } from 'react-icons/ai'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import rotate_image from '../../Images/rotate-image.png';
import Mandli2024Element from '../../Images/Mandli2024/MainBg2024.png';
import { useNavigate } from "react-router-dom";
import { getAdminRoutes } from '../../Helper/Token';



const toastStyle = {
  position: "bottom-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
}


function AdminLogin() {
  var crypt = new Crypt();
  const navigate = useNavigate();

  // VAriables
  const [showPassword, setShowPassword] = useState(false);
  const [stayLoggedIn, setStayLoggedIn] = useState("");
  const [loginData, setLoginData] = useState({
    email: "",
    password: ""
  });

  // Check stay Logged in
  const handleChecked = (e) => {
    if (e.target.checked == true) {
      setStayLoggedIn(true)
    } else {
      setStayLoggedIn(false)
    }
  }

  // Get Enterd Login Data
  const handleChange = (e) => {
    setLoginData({
      ...loginData,
      [e.target.name]: e.target.value
    })
  }


  // Login Api
  const handleLogin = (e) => {
    e.preventDefault();
    // Encrypt enterd password
    let encryptedPassword = crypt.encrypt(process.env.REACT_APP_PUBLIC_KEY, loginData.password);

    const loginInfo = new FormData();
    loginInfo.append("email", loginData.email)
    loginInfo.append("password", encryptedPassword)
    // loginInfo.append("password", loginData.password)

    // API
    axios.post(getAdminLogin, loginInfo)
      .then((res) => {
        localStorage.clear();
        sessionStorage.clear();
        if (stayLoggedIn) {
          localStorage.setItem("adminToken", res.data.token)
        } else {
          sessionStorage.setItem("adminToken", res.data.token)
        }
        toast.success(res.data.message, toastStyle);
        axios.defaults.headers.common["Authorization"] = "Bearer " + res.data.token
        axios.patch(getAdminDetails)
          .then((res) => {
            const accessData = getAdminRoutes.filter((route) => {
              if (res.data.admin?.access?.length > 0) {
                return res.data.admin?.access?.some((accessItem) => accessItem.AccessName === route.key);
              } else {
                return getAdminRoutes
              }
            });
            if (stayLoggedIn) {
              localStorage.setItem("adminAccess", JSON.stringify(accessData));
              setTimeout(() => {
                navigate(accessData[0].path)
              }, 1000)
            } else {
              sessionStorage.setItem("adminAccess", JSON.stringify(accessData));
              setTimeout(() => {
                navigate(accessData[0].path)
              }, 1000)
            }
          })
      })
      .catch((err) => {
        toast.error(err.response.data.message, toastStyle);
      })
  }

  // Show Hide PAssword
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="Login mandli-bg">
      <div className='rotate-image'>
        <img src={Mandli2024Element} alt="" />
      </div>
      <div className="header-top-area-shape"></div>
      <div className="container">
      <div className='login-block'>
        <div className='logo'>
          {/* <NavLink to="/admin/dashboard"> */}
          <div id="nav">
            <h1>mand<span>li</span></h1>
          </div>
          {/* </NavLink> */}
        </div>
        <div className='at-form'>
          <div className='login-form'>
            <h3>Login in to Admin</h3>
            <Form>
              <Form.Group className="form-control" controlId="formBasicEmail">
                <Form.Control type="email" placeholder="Enter email" name="email" onChange={(e) => handleChange(e)} />
              </Form.Group>
              <Form.Group className="form-control password" controlId="formBasicPassword">
                <Form.Control type={showPassword ? "text" : "password"} placeholder="Password" name="password" onChange={(e) => handleChange(e)} />
                <Button variant="outline-secondary" onClick={toggleShowPassword}>{!showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}</Button>
              </Form.Group>
              <Form.Group className="checkbox" controlId="formBasicCheckbox">
                <div className='check'>
                  <Form.Check type="checkbox" onChange={(e) => handleChecked(e)} name="stayLoggedIn" label="Keep me logged in" />
                </div>
                <NavLink to="/admin/forgotpassword" >Forgot Password</NavLink>
              </Form.Group>
              <Button variant="contained" type="submit" onClick={(e) => handleLogin(e)} className='primaryBtn common-btn'>
                Login
              </Button>
            </Form>
          </div>
        </div>
      </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  )
}

export default AdminLogin