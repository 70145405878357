import React, { useEffect, useState } from 'react'
import img from '../../Images/code.png'
import './Users.css'
import DownloadIcon from '@mui/icons-material/Download';
import { NavLink, useParams } from 'react-router-dom';
import { getAdminToken } from '../../Helper/Token';
import axios from 'axios';
import { AdmingetUserOrderDetails, getUserTicketsOrderDetails } from '../../Api';
import { IconButton } from '@mui/material';

function ViewOrder() {

    const orderId = useParams()

    // Get Order Details
    const [orderDetails, setOrderDetails] = useState({})
    const fetchOrderDetails = (e) => {
        getAdminToken();
        // axios.get(`${getUserTicketsOrderDetails}/${orderId.id}`)
        axios.get(`${AdmingetUserOrderDetails}/${orderId.id}`)
            .then((res) => {
                res.data.result[0].PurchaseticketsData.map((ele) => {
                    const dateObject = new Date(ele.createdAt);

                    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

                    const day = dateObject.getDate();
                    const monthIndex = dateObject.getMonth();
                    const year = dateObject.getFullYear();
                    const hours = dateObject.getHours();
                    const minutes = dateObject.getMinutes();
                    const amPm = hours >= 12 ? 'PM' : 'AM';
                    const hours12 = hours % 12 || 12; // Convert to 12-hour format

                    ele.formattedDateTime = `${day} ${months[monthIndex]}, ${year} ${hours12}:${minutes < 10 ? '0' : ''}${minutes}${amPm}`;

                    const description = ele.TicketInfo.Description;
                    const descriptionParts = description?.split(" ");
                    let SpecificDays;
                    const ComboDiscription = ele.TicketInfo.Description
                    const IsCombo = ele.isCombo

                    if (IsCombo) {
                        const Combomatch = ComboDiscription.split(' ')
                        const startDay = Combomatch[0].replace('th', '').replace('rd', '').replace('st', '').replace('nd', '');
                        const endDay = Combomatch[2].replace('rd', '').replace('th', '').replace('st', '').replace('nd', '');
                        const extractedRange = `${startDay} to ${endDay}`;

                        ele.SpecificDays = extractedRange;
                    }
                    else {
                        if (descriptionParts && descriptionParts.length >= 1) {
                            const dayPart = descriptionParts;
                            const day = parseInt(dayPart); // Convert the day part to an integer
                            if (!isNaN(day)) {
                                ele.SpecificDays = day;
                            }
                        }
                    }
                })
                setOrderDetails(res.data.result[0])
            })
    }
    useEffect(() => {
        if (orderId?.id) {
            fetchOrderDetails()
        }
    }, [orderId])

    // Download PDF
    const backendBaseUrl = 'http://localhost:3001';
    const handleDownloadClick = (row) => {
        const link = document.createElement('a');
        link.href = `${backendBaseUrl}/${row.PdfUrl}`;
        link.download = `${backendBaseUrl}/${row.PdfUrl}`;
        link.setAttribute('target', "_blank")
        link.setAttribute('download', 'Mandli.pdf')
        link.setAttribute('rel', "noreferrer")
        document.body.appendChild(link);
        link.click();
    };

    return (
        <>
            <section className='cont'>

                <div className="dashboard dashboard-right-header fr05s-usr-quotsn05s">
                    <div className="left-text">
                        <h4>Order #{orderDetails.OrderId}</h4>
                        <p className='m-0'>Here are all tickets you have purchase in order #{orderDetails.OrderId}</p>
                    </div>
                </div>

                <div className='tickets-container admin_tickets-container'>
                    {
                        orderDetails?.PurchaseticketsData?.map((ticket) => {
                            return (
                                <div class="ticket">
                                    <div class="main">
                                        <div class="name">
                                            <h1>
                                                <span>{ticket?.TicketInfo?.TicketTitle}</span>
                                            </h1>
                                        </div>
                                        <div class="date">
                                            <h1>{ticket.SpecificDays}<span>{ticket.TicketInfo?.Description}</span></h1>
                                        </div>
                                        <div className='where_and_when'>
                                            <div class="where_when">
                                                <div class="place">
                                                    <div class="venue">
                                                        <h1>time</h1>
                                                        <p>08:00 PM onwards</p>
                                                    </div>
                                                    <div class="venue">
                                                        <h1>venue</h1>
                                                        <p>Mandli Garba 2023, near <br /> Amaryllis crossing, Canal Road, Shilaj, Ahmedabad, Gujarat - 380059</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="qrcode">
                                                <div class="ticketDevider"></div>
                                                <div class="qrCode">
                                                    <img src={`http://localhost:3001/${ticket.qrCode}`} alt="Mandli Tickets QR Code" />
                                                    {/* <img src={img} alt="Mandli Tickets QR Code" /> */}
                                                    <h4>Ticket Id</h4>
                                                    <p>{ticket.TicketNo}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='download'>
                                            <IconButton aria-label="Download PDF" className='dyFlextIcon' target='_blank' onClick={() => handleDownloadClick(ticket)} download>
                                                <DownloadIcon />
                                            </IconButton>
                                        </div>
                                    </div>

                                </div>
                            )
                        })
                    }
                </div>
                <div className='terms'>
                    <h3>Note</h3>
                    <ul >
                        <li>
                            To access the express entry, all attendees must enter together. Otherwise, you'll need to collect all the passes at the ticket counter.
                        </li>
                    </ul>
                
                <h3>Terms & Conditions</h3>
                <ul>
                    <li>Smoking, Alcohol, Prohibited Substances on the premises is prohibited.</li>
                    <li>Individual under the influence of alcohol or drugs will not be allowed entry and if Found in that state on premise, then will be evicted.</li>

                    <li>Right to admission is reserved with the organizer</li>
                    <li>The organizers will not be responsible for any loss of property or accidents of the attendees.</li>

                    <li>Parking on the road is strictly prohibited; Parking spaces will be provided, kindly park vehicles there. Carpools are promoted.</li>
                    <li>No refund will be provided due to any unforeseeable events</li>
                    <li>Duplicate passes will not be issued for lost or stolen tickets.</li>
                    <li>Unlawful resale (or attempted unlawful resale) of a pass would lead to seizure or cancellation of that pass without refund or other compensation.</li>
                </ul>
            </div>
        </section >
        </>
    )
}

export default ViewOrder