import React, { useEffect, useState } from 'react'
import './AdminSidebar.css'
import { RxDashboard } from 'react-icons/rx'
import { HiOutlineTicket } from 'react-icons/hi'
import { FiUsers } from 'react-icons/fi'
import { AiOutlineTags } from 'react-icons/ai'
import { MdOutlineLogout } from 'react-icons/md'
import { MdOutlineSupportAgent } from 'react-icons/md'
import { PiMaskHappyLight } from 'react-icons/pi'
import { PiMicrosoftTeamsLogoLight } from 'react-icons/pi'
import { BsTicketPerforated } from 'react-icons/bs'
import { BsCreditCard2Front } from 'react-icons/bs'
import { NavLink } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import PeopleIcon from '@mui/icons-material/People';
import { getAdminRoutes, getAdminToken, getFinalRoutes } from '../../../Helper/Token'
import axios from 'axios'
import { getAdminDetails } from '../../../Api'
import { BsChevronDown } from 'react-icons/bs'


function AdminSidebar({ children, closeMenu }) {

    const navigate = useNavigate();
    const [showSubMenu, setShowSubMenu] = useState(false);
    const [showSubMenuOffline, setshowSubMenuOffline] = useState(false);
    const toggleSubMenu = () => {
        setShowSubMenu(!showSubMenu);
    };

    const toggleSubMenuOffline = () => {
        setshowSubMenuOffline(!showSubMenuOffline);
    }

    const handleLogOut = (e) => {
        e.preventDefault();
        localStorage.clear();
        sessionStorage.clear();
        navigate("/admin/login")
    }

    // Get Admin Details
    const [adminDetails, setAdminDetails] = useState({});
    const fetchAdminDetails = () => {
        getAdminToken();
        axios.patch(getAdminDetails)
            .then((res) => {
                setAdminDetails(res.data.admin)
            })
    }
    useEffect(() => {
        fetchAdminDetails();
    }, [])

    // Check Admin Access
    var data = []
    var finalroutes = []

    if (localStorage.getItem('adminAccess')) data = JSON.parse(localStorage.getItem('adminAccess'))
    if (sessionStorage.getItem('adminAccess')) data = JSON.parse(sessionStorage.getItem('adminAccess'))

    if (data) {
        const accessData = getAdminRoutes.filter((route) => {
            return data?.some((accessItem) => accessItem.path === route.path);
        });
        finalroutes = accessData;
    }

    const handleSidebarButtonClick = () => {
        closeMenu();
    }



    return (
        <div className='Admin_SideBar'>
            <div className="container flexted-body">

                <div className="usersidebar ">
                    <div className='Admin_Info'>
                        <h1>{adminDetails.name}</h1>
                        <span>{adminDetails.email}</span>
                    </div>
                    {finalroutes.map((route) => (
                        <>
                            {route.name === 'Online' ? (
                                <NavLink className="userlink order" key={route.id} id={route.id} onClick={toggleSubMenu}>
                                    <div className="ussericon">{route.icons}</div>
                                    <div className="user-link_text">Online Order</div>
                                    <div className={`user-link_icons ${showSubMenu ? 'active' : ''}`}>
                                        <BsChevronDown />
                                    </div>
                                </NavLink>
                            ) : route.name === 'Offline' ? (
                                <NavLink className="userlink order" key={route.id} id={route.id} onClick={toggleSubMenuOffline}>
                                    <div className="ussericon">{route.icons}</div>
                                    <div className="user-link_text">Offline Order</div>
                                    <div className={`user-link_icons ${showSubMenuOffline ? 'active' : ''}`}>
                                        <BsChevronDown />
                                    </div>
                                </NavLink>
                            ) : (
                                <NavLink className="userlink" to={route.path} key={route.id} id={route.id} onClick={closeMenu}>
                                    <div className="ussericon">{route.icons}</div>
                                    <div className="user-link_text">{route.name}</div>
                                </NavLink>
                            )}
                            {showSubMenu && route.name === 'Online' && (
                                <ul className={`submenu submenu-entered ${showSubMenu ? 'active' : ''}`}>
                                    <li>
                                        <NavLink className="userlink Dropdown_menus_ad" to="/admin/orders" onClick={closeMenu}>
                                            <div className="ussericon">{route.icons}</div>
                                            <div className="user-link_text">Processed</div>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="userlink Dropdown_menus_ad" to="/admin/orders/unprocessed" onClick={closeMenu}>
                                            <div className="ussericon">{route.icons}</div>
                                            <div className="user-link_text">Unprocessed</div>
                                        </NavLink>
                                    </li>
                                </ul>
                            )}

                            {showSubMenuOffline && route.name === 'Offline' && (
                                <ul className={`submenu submenu-entered ${showSubMenuOffline ? 'active' : ''}`}>
                                    <li>
                                        <NavLink className="userlink Dropdown_menus_ad" to="/admin/pre-event-orders" onClick={closeMenu}>
                                            <div className="ussericon">{route.icons}</div>
                                            <div className="user-link_text">Pre Event</div>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="userlink Dropdown_menus_ad" to="/admin/on-going-event-orders" onClick={closeMenu}>
                                            <div className="ussericon">{route.icons}</div>
                                            <div className="user-link_text">On Going Event</div>
                                        </NavLink>
                                    </li>
                                </ul>
                            )}
                        </>
                    ))}

                    <NavLink to="/logout" key="logout" className="userlink" id="logout" onClick={(e) => handleLogOut(e)} >
                        <div className="ussericon"><MdOutlineLogout /></div>
                        <div className="user-link_text">Log Out</div>
                    </NavLink>
                </div>
                <main className='main-wrap-user' > <div className='userdashboardcnt'>{children}</div> </main>
            </div>
        </div>
    )
}

export default AdminSidebar

