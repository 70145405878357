import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

const ApexChart = ({ data }) => {
    const [chartData, setChartData] = useState([]);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const extractedData = data.slice(1).map(item => item[1]);
        const extractedCategories = data.slice(1).map(item => item[0]);

        setChartData(extractedData);
        setCategories(extractedCategories);
    }, [data]);

    const options = {
        chart: {
            type: 'bar',
            height: 430,
        },
        plotOptions: {
            bar: {
                horizontal: window.innerWidth <= 768,
                dataLabels: {
                    position: 'top',
                },
            },
        },
        dataLabels: {
            enabled: true,
            offsetX: -6,
            style: {
                fontSize: '12px',
                colors: ['#fff'],
            },
        },
        stroke: {
            show: true,
            width: 1,
            colors: ['#fff'],
        },
        tooltip: {
            shared: true,
            intersect: false,
        },
        xaxis: {
            categories: categories,
        },
        colors: ['#730000'],
    };

    return (
        <div id="chart">
            <ReactApexChart
                options={options}
                series={[{ data: chartData }]}
                type="bar"
                height={430}
            />
        </div>
    );
};

export default ApexChart;
