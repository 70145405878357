import React from 'react'
import Header from '../Components/Header/Header'
import SiteFooter from '../Components/Footer/SiteFooter'
import { useEffect } from 'react';
import { useState } from 'react';
import HomeHeader from '../Components/Header/HomeHeader';


function PublicLayout({ page }) {

  const [isLogin, setIsLogin] = useState(false)
  const [pageLoaded, setPageLoaded] = useState(false)
  let token;
  // let token = getToken()

  useEffect(() => {
    if (localStorage.getItem("token") != null) {
      token = localStorage.getItem('token');
      setIsLogin(true)
    } else if (sessionStorage.getItem('token') != null) {
      token = sessionStorage.getItem('token')
      setIsLogin(true)
    } else {
      setIsLogin(false)
    }
    setPageLoaded(true)
  }, [])

  // for header
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);
  const [isHeaderchanged, setIsHeaderchanged] = useState(true);
  const handleScroll = () => {
    if (window.location.pathname === '/' && window.scrollY > 10) {
      setIsHeaderFixed(true);
      setIsHeaderchanged(false);
    } else {
      setIsHeaderFixed(false);
      setIsHeaderchanged(true);
    }
  };

  console.log(isHeaderchanged, "isHeaderchanged")

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);  // Cleanup on unmount
  }, [handleScroll]);

  const renderHeader = () => {
    if (window.location.pathname === '/') {
      return <HomeHeader isHeaderFixed={isHeaderFixed} />;  // Specific header for "/buy-now" page
    } else {
      return <Header />;  // Default header for other pages
    }
  };

  return (
    <>
      <div className={`main-wrapper ${window.location.pathname === '/' ? "removepadding" : ""} ${window.location.pathname === '/buy-now' ? "but-now-page" : ""}${isHeaderFixed ? 'removepadding' : ''} ${isHeaderchanged ? 'addSpace' : ''}`}>
        {renderHeader()}
        {page}
        <SiteFooter />
      </div>
    </>
  )
}

export default PublicLayout