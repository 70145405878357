import React, { useEffect, useState } from 'react';
import './Members.css'
import { CiSearch } from 'react-icons/ci';
import { MdOutlineDelete } from 'react-icons/md';
import { GoPersonAdd } from 'react-icons/go';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import axios from 'axios';
import { getAdminToken } from '../../Helper/Token';
import { getAccessList, getAddMember, getAdminCreateTicket, getAdminEditTicket, getDeleteMember, getEditMember, getEnableDisableTicket, getMembersList, getTicketsList } from '../../Api';
import { Box, Button, FormControlLabel, IconButton, Stack } from '@mui/material';
import { Col, Modal, Pagination, Row, Switch, Text, useModal } from '@nextui-org/react';
import Form from 'react-bootstrap/Form';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DataGrid } from '@mui/x-data-grid';
import { SuperBalls } from '@uiball/loaders';
import { NavLink, useNavigate } from 'react-router-dom';
import { AiOutlineEye } from 'react-icons/ai';


const toastStyle = {
  position: "bottom-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
}

const accessName = {
  DASHBOARD: "Dashboard",
  TICKETS: "Tickets",
  PHASES: "Phases",
  SOLDTICKETS: "Sold Tickets",
  ORDERS: "Orders",
  USERS: "Users",
  TEAMMEMBERS: "Team Members",
  SUPPORT: "Support",
  CMS: "CMS",
  PREEVENTORDERS: "Pre Event Orders",
  ONGOINGEVENTORDERS: "On Going Event Orders",
  STUDENTORDERS: "Student Orders"
}


function Members() {

  const navigate = useNavigate()

  const columns = [
    { field: 'id', headerName: 'Id', width: 70 },
    { field: 'name', headerName: 'Name', width: 200, },
    { field: 'email', headerName: 'Email', width: 200, },
    { field: 'totalSoldTickets', headerName: 'Total Sold Tickets', width: 200, },
    {
      field: "actions", headerName: "Action", sortable: false, width: 200,
      renderCell: (prevents) => {
        return (
          <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
            <MatEdit index={prevents.row} />
            <MatDelete index={prevents.row} />
            <MatView index={prevents.row} />
          </div>
        );
      }
    }
  ];

  const MatEdit = ({ index }) => {
    const handleEditClick = (e) => {
      openEdit();
      setSelectedAccess(index.access)
      setEditData({
        ...editData,
        name: index.name,
        email: index.email,
        access: index.access,
        _id: index._id
      })
    }

    return (
      <FormControlLabel
        control={
          <IconButton aria-label="add an alarm" className='dyFlextIcon' onClick={(e) => handleEditClick(e)}>
            <ModeEditOutlineOutlinedIcon />
          </IconButton>
        }
      />
    );
  };

  const MatView = ({ index }) => {
    return (
      <FormControlLabel
        control={
          <NavLink to={index.type === "SuperAdmin" ? `/admin/team-members/view-app-order/${index._id}` : `/admin/team-members/view-order/${index._id}`}>
            <IconButton aria-label="add an alarm" className='dyFlextIcon'>
              <AiOutlineEye />
            </IconButton>
          </NavLink>
        }
      />
    );
  };

  function MatDelete({ index }) {
    const { setVisible: setProductDeleteModalVisible, bindings: productDeleteModalBindings } = useModal();
    const closeProductDeleteModel = () => {
      setProductDeleteModalVisible(false)
    }

    const handleDeleteProduct = (e) => {
      getAdminToken();
      axios.delete(`${getDeleteMember}/${index._id}`)
        .then((res) => {
          fetchMembers()
          toast.success(res.data.message, toastStyle);
        }).catch((err) => {
          toast.error(err.response.data.message, toastStyle);
        })
    }
    return <FormControlLabel
      control={<>
        <div className='ActionButtons'>
          <IconButton aria-label="add an alarm" onClick={() => setProductDeleteModalVisible(true)} className='dyFlextIcon'>
            <MdOutlineDelete />
          </IconButton>
          <Modal scroll width="600px" closeButton aria-labelledby="modal-title" aria-describedby="modal-description" {...productDeleteModalBindings} >
            <Modal.Header justify='start'>
              <Text id="modal-title" size={18}>
                Are You <Text b id="modal-title" size={18}> Sure!</Text>
              </Text>
            </Modal.Header>
            <Modal.Body className='deleteModel'>
              <Row className="mb-3">
                <h2>Want to Delete <strong style={{ color: "var(--peimary)" }}>{index?.name}</strong> Member?</h2>
                <p>Do you really want to delete these Member? He/She can't Access admin panel anymore if you delete!</p>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button auto flat className='modelClose' onClick={() => closeProductDeleteModel()}>
                No, Keep it
              </Button>
              <Button auto className='modelSubmit common-btn' onClick={(e) => handleDeleteProduct(e)}>
                Yes, Delete it
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>} />;
  }

  // Search
  const [search, setSearch] = useState("")
  const handleSearch = (e) => {
    setSearch(e.target.value)
  }

  // Pagination
  const [pageState, setPageState] = useState({
    total: 0,
    totalPages: 0,
    page: 1,
    pageSize: 10
  })
  const handlePageChange = (selectedPage) => {
    setPageState((prevState) => ({ ...prevState, page: selectedPage }));
  };
  const ResultInfo = () => {
    const start = (pageState.page - 1) * pageState.pageSize + 1;
    const end = Math.min(start + pageState.pageSize - 1, pageState.total);

    return (
      <div className="result-info">
        Showing <span>{start}-{end}</span> of <span>{pageState.total}</span> Members
      </div>
    );
  };

  // Get All Members List
  const [rows, setRows] = useState([])
  const fetchMembers = () => {
    getAdminToken();
    axios.get(`${getMembersList}?search=${search}&page=${pageState.page}&limit=${pageState.pageSize}`)
      .then((res) => {
        const json = res.data.result;
        setPageState((prevState) => ({ ...prevState, total: json.totalDocs, totalPages: json.totalPages }));
        res.data.result.docs.map((ele, i) => {
          ele.srno = (res.data.result.page * 10) + (i + 1) - 10
          ele.id = i + 1;
        });
        setRows(res.data.result.docs)
      })
  }
  useEffect(() => {
    fetchMembers()
  }, [search, pageState.page, pageState.pageSize])

  // Get all Permissions
  const [memberAccess, setMemberAccess] = useState({
    permissions: [],
  });
  const [selectedAccess, setSelectedAccess] = useState([]);
  const [Permissions, setPermissions] = useState([]);
  const fetchPermissions = () => {
    axios.get(getAccessList)
      .then((res) => {
        setPermissions(res.data.result)
      })
  }
  useEffect(() => {
    fetchPermissions()
  }, [])

  // Get Selected Permissions From Checkbox
  const handleChecked = (e, data) => {
    const { value, checked } = e.target;
    const { permissions } = memberAccess;

    if (data == "edit") {
      if (selectedAccess.includes(value)) {
        const data = selectedAccess.filter((res) => (res !== value))
        setSelectedAccess(data)
      }
      else {
        const data = [...selectedAccess]
        data.push(value)
        setSelectedAccess(data)
      }
    }

    if (checked) {
      setMemberAccess({
        permissions: [...permissions, value],
      })
    } else {
      setMemberAccess({
        permissions: permissions.filter((e) => e !== value)
      })
    }
  };


  // Add Edit Tickets Popup
  const { setVisible: setProductEditModalVisible, bindings: addTicketModalBindings } = useModal();
  const { setVisible: setTicketEditModalVisible, bindings: editTicketModalBindings } = useModal();

  const openAdd = () => setProductEditModalVisible(true)
  const closeAdd = () => setProductEditModalVisible(false)

  const openEdit = () => setTicketEditModalVisible(true)
  const closeEdit = (e) => {
    e.preventDefault();
    setTicketEditModalVisible(false)
    setEditData({})
  }

  // Add new ticket
  const [newData, setNewData] = useState({
    name: "",
    email: "",
    access: []
  })

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewData({
      ...newData,
      [name]: value
    })
  }

  const handleAddMember = (e) => {
    e.preventDefault();
    getAdminToken();
    const memberObj = {
      ...newData,
      access: memberAccess.permissions
    }
    axios.post(getAddMember, memberObj)
      .then((res) => {
        closeAdd();
        toast.success(res.data.message, toastStyle);
        fetchMembers();
      })
      .catch((err) => {
        toast.error(err.response.data.message, toastStyle);
      })
  }

  // Edit Ticket
  const [editData, setEditData] = useState({})

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditData({
      ...editData,
      [name]: value
    })
  }

  const handleEditMember = (e) => {
    e.preventDefault();
    getAdminToken();
    const editObj = {
      ...editData,
      access: selectedAccess
    }
    axios.put(`${getEditMember}/${editData._id}`, editObj)
      .then((res) => {
        closeEdit(e);
        fetchMembers();
        toast.success(res.data.message, toastStyle);
      })
      .catch((err) => {
        toast.error(err.response.data.message, toastStyle);
      })
  }


  return (
    <>
      <section className='adminDashboard'>
        <div className="dashboard-right-header fr05s-usr-quotsn05s">
          <div className="left-text">
            <h4>Team Members </h4>
            <p className="m-0">Here is all Team Members
            </p>
          </div>
        </div>

        <div className='filter-Search'>
          <div className='filter-block'>
            <div className='qutrowftr08s'>
              <div className='create-block'>
                <Button className='usdercmntbtn common-btn' onClick={openAdd}><GoPersonAdd />Add Member</Button>
              </div>
            </div>
          </div>
          <div className="search-box form-item position-relative">
            <Form.Control type="text" placeholder="Search.." name="seach" className="search-input w-100" onChange={(e) => handleSearch(e)} />
            <CiSearch />
          </div>
        </div>

        <div className="ticketsContainer">
          <Box sx={{ width: '100%' }}>
            <DataGrid
              autoHeight {...rows}
              rows={rows}
              columns={columns}
              pageSize={pageState.pageSize}
              disableSelectionOnClick
              pagination={false}
              components={{
                NoRowsOverlay: () => (
                  <Stack height="100%" alignItems="center" justifyContent="center">
                    <SuperBalls size={45} speed={1.4} color="var(--primary)" />
                  </Stack>
                )
              }}
            />
          </Box>
        </div>
        <div className='pagination-block'>
          <ResultInfo />
          <Pagination
            loop
            color="success"
            total={pageState.totalPages}
            initialPage={1}
            onChange={(page) => handlePageChange(page)}
            currentPage={pageState.page}
          />
        </div>
      </section>

      {/* Add Ticket Popup */}
      <Modal scroll width="600px" closeButton aria-labelledby="modal-title" aria-describedby="modal-description" {...addTicketModalBindings} >
        <Modal.Header justify='start'>
          <Text id="modal-title" size={18}>
            Add New Member
          </Text>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3 gap-3">
            <Form.Group as={Col} className="form-control-product" controlId="formBasicImage">
              <Form.Label>Name</Form.Label>
              <Form.Control className='w-100' type="text" placeholder="Name" name="name" onChange={(e) => handleChange(e)} />
            </Form.Group>
          </Row>
          <Row className="mb-3 gap-3">
            <Form.Group as={Col} controlId="form-control-product">
              <Form.Label>Email</Form.Label>
              <Form.Control className='w-100' type="email" placeholder="Email" name="email" onChange={(e) => handleChange(e)} />
            </Form.Group>
          </Row>
          <Row className="mb-3 gap-3">
            <Form.Group className="access-row w-100">
              <Form.Label className='w-100'>Select Access</Form.Label>
              {
                Array.from(Permissions).map((ele, i) => {
                  return (
                    <Form.Check type="checkbox" id={ele._id}
                      name={ele.AccessName}
                      value={ele._id}
                      label={accessName[ele.AccessName]}
                      onChange={(e) => handleChecked(e, "add")}
                    />
                  )
                })
              }
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button auto flat className='modelClose' onClick={() => closeAdd()}>
            Cancel
          </Button>
          <Button auto className='modelSubmit common-btn' onClick={(e) => handleAddMember(e)}>
            Add Member
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit Ticket Popup */}
      <Modal scroll width="600px" closeButton aria-labelledby="modal-title" aria-describedby="modal-description" {...editTicketModalBindings} >
        <Modal.Header justify='start'>
          <Text id="modal-title" size={18}>
            Edit Ticket
          </Text>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3 gap-3">
            <Form.Group as={Col} className="form-control-product" controlId="formBasicImage">
              <Form.Label>Name</Form.Label>
              <Form.Control className='w-100' type="text" placeholder="Name" value={editData.name} name="name" onChange={(e) => handleEditChange(e)} />
            </Form.Group>
          </Row>
          <Row className="mb-3 gap-3">
            <Form.Group as={Col} controlId="form-control-product">
              <Form.Label>Email</Form.Label>
              <Form.Control className='w-100' type="email" placeholder="Email" value={editData.email} name="email" onChange={(e) => handleEditChange(e)} />
            </Form.Group>
          </Row>
          <Row className="mb-3 gap-3">
            <Form.Group className="access-row w-100">
              <Form.Label className='w-100'>Select Access</Form.Label>
              {
                Array.from(Permissions).map((ele, i) => {
                  return (
                    <Form.Check type="checkbox" id={ele._id}
                      name={ele.AccessName}
                      value={ele._id}
                      label={ele.AccessName}
                      onChange={(e) => handleChecked(e, "edit")}
                      checked={selectedAccess?.includes(ele._id)}
                    />
                  )
                })
              }
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button auto flat className='modelClose' onClick={(e) => closeEdit(e)}>
            Cancel
          </Button>
          <Button auto className='modelSubmit common-btn' onClick={(e) => handleEditMember(e)}>
            Edit Member
          </Button>
        </Modal.Footer>
      </Modal >

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}
export default Members