import React, { useEffect, useState } from 'react'
import './ThankYou.css'
import LogoImg from '../../Images/Mandli/mandli-logo.svg'
import { NavLink, Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { getPaymentDetails, getUserTicketsOrderDetails } from '../../Api';
import { getToken } from '../../Helper/Token';
import axios from 'axios';
import PaymentFail from '../../Images/Mandli/pf2.png';


function ThankYou() {
  const [searchParams] = useSearchParams();
  const paymentResponse = JSON.parse(searchParams.get('paymentResponse'));
  const [hasFetched, setHasFetched] = useState(false);

  const fetchPaymentDetails = () => {
    axios
      .post(getPaymentDetails, paymentResponse)
      .then((res) => {
        if (res) {
          if (res.data.orderid !== "") {
            setHasFetched(true);
            fetchOrderDetails(res.data.orderid);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (paymentResponse && !hasFetched) {
      const timeoutId = setTimeout(() => {
        fetchPaymentDetails();
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [paymentResponse, hasFetched]);


  const currentYear = new Date().getFullYear();
  const id = localStorage.getItem("orderId");
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (id === "" || id === null || id === undefined) {
  //     setTimeout(() => {
  //       navigate('/orders')
  //     }, 5000)
  //   }
  // }, [id])

  // variables
  const [rows, setRows] = useState([])
  const [PaymentStatus, setPaymentStatus] = useState(false)
  const [isLoading, setIsLoding] = useState(true)
  const [statusChanged, setStatusChanged] = useState(false)
  const [completedOrderId, setCompleatedOrderId] = useState("")
  const [paymentCCStatus, setPaymentCCStatus] = useState("")

  const fetchOrderDetails = (orderId) => {
    axios.get(`${getUserTicketsOrderDetails}/${orderId}`)
      .then((res) => {
        console.log(res);
        if (res.data.isExist[0].CC_PaymentStatus) {
          setStatusChanged(true)
          if (res.data.isExist[0].CC_PaymentStatus === "Transaction Successful") {
            setPaymentStatus(true)
            setCompleatedOrderId(res.data.isExist[0]._id)
            res.data.isExist.map((ele, i) => {
              ele.Title = ele.TicketInfo.TicketTitle
              ele.qty = ele.TicketInfo.PurchasedTickets
              ele.Description = ele.TicketInfo.Description
              ele.Price = ele.TicketInfo.Price

              ele.TicketInfo.forEach((ele) => {
                if (!ele.isCombo && ele.TicketTitle) {
                  const data = []
                  const parts = ele.TicketTitle.split(':');
                  const ticketDay = parts[0]?.trim();
                  const ticketName = parts[1]?.trim();
                  const ticketNameData = {
                    ticketDay,
                    ticketName,
                  };
                  data.push(ticketNameData)
                  ele.ticketNameData = data
                }
              });
            });
            setRows(res.data.isExist)
            setIsLoding(false)
            localStorage.removeItem('purchaseTicketsData')
            localStorage.removeItem('orderId')
          } else if (res.data.isExist[0].CC_PaymentStatus === "Transaction Canceled") {
            setIsLoding(false)
            setPaymentStatus(false)
            setPaymentCCStatus("Canceled")
          } else {
            setIsLoding(false)
            setPaymentStatus(false)
            setPaymentCCStatus("Failed")
          }
        } else {
          setStatusChanged(false)
        }
      });
  }

  return (
    <>
      {isLoading ? (
        <section className='ThankYou'>
          <div className='container'>
            <div className='main_body'>
              <div className='container'>
                <div className='thank_you_container'>

                  <div className='userbox-lg-search logo'>
                    <NavLink to="/" onClick={() => Navigate('/')}><img src={LogoImg} width={"200"} /></NavLink>
                  </div>

                  <div id='outer' className='Loader_ThankYou'>
                    <div id='middle'>
                      <div id='inner'>
                      </div>
                    </div>
                  </div>
                  <div className='Error_message_wait'>
                    <h1 className='animate-charcter'>Please wait while we are processing....</h1>
                  </div>

                  <div className='thank_you_footer'>
                    <div className='Site_footer'>
                      <div className='container'>
                        <div className='coppy_rights'>
                          <h1>&copy; {currentYear} Mandli</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className='ThankYou'>
          <div className='container'>
            <div className='main_body'>
              <div className='container'>
                <div className='thank_you_container'>

                  <div className='userbox-lg-search logo'>
                    <NavLink to="/" onClick={() => Navigate('/')}><img src={LogoImg} width={"200"} /></NavLink>
                  </div>

                  {PaymentStatus ?
                    <div className='payment_completed'>
                      <div className='Block_details_order'>
                        <div className='tnk_title'>
                          <h3 className="animate-charcter">Order successful</h3>
                        </div>
                        <div className='order_details_before_coming'>
                          <p>Check your <b>email</b> for a copy of your ticket(s) and further details.</p>
                        </div>
                        <div className="view_order_btn">
                          <NavLink to={`/orders/vieworder/${completedOrderId}`} className="usdercmntbtn common-btn" >
                            <span>View Order</span>
                          </NavLink>
                        </div>
                      </div>

                      {rows.map((ticket, index) => (
                        <div className='Order_summery checkoutSummary' key={index}>

                          <div className='title_rows'>

                            <div className="ticketsBlock show ticketsBlock">
                              {ticket.TicketInfo.map((info, infoIndex) => (
                                <div className={`ticket selected ${info.TicketTitle === "Season Pass" ? "combo" : ""}`} key={infoIndex}>
                                  <div className='border'></div>
                                  <div className='dayName'>
                                    <h3>{info.TicketTitle === "Season Pass" ? "all 9 days" : info.ticketNameData[0].ticketDay} </h3>

                                  </div>
                                  <div className='ticketData'>
                                    <div className='name'>
                                      <h2>{ticket.isCombo ? ticket.TicketTitle : ticket.ticketNameData[0].tickeDiscription} {ticket.isCombo ? <span>{ticket.Description}</span> : ''}</h2>
                                    </div>
                                    <div className='booking'>
                                      <div className="price">
                                        {/* <h3>&#8377;{info.Price}</h3> */}
                                        <h3>
                                          {info.SalePrice && info.SalePrice > 0 ? (
                                            <>
                                              <span className='strikePrice' style={{ textDecoration: 'line-through', marginRight: '10px' }}>
                                                &#8377;{info.Price}
                                              </span>
                                              &#8377;{info.SalePrice}
                                            </>
                                          ) : (
                                            <>&#8377;{info.Price}</>
                                          )}
                                        </h3>
                                      </div>
                                      <div className='quantity'>
                                        <div className='action'>
                                          <span className='qty'>{info.PurchasedTickets}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>

                      ))}

                    </div>
                    :
                    <div className='Block_details_order'>
                      <div className='tnk_title'>
                        <img src={PaymentFail} alt="" />
                        <h3 className="animate-charcter payment_failed">Oops,<br />Payment {paymentCCStatus}</h3>
                      </div>
                      <div className='order_details_before_coming payment_failed'>
                        <p>Please try other payment mode</p>
                      </div>
                      <div className="view_order_btn">
                        <NavLink to="/checkout" className="usdercmntbtn common-btn" >
                          <span>Re-Try</span>
                        </NavLink>
                      </div>
                    </div>}

                  <div className='thank_you_footer'>
                    <div className='Site_footer'>
                      <div className='container'>
                        <div className='coppy_rights'>
                          <h1>&copy; {currentYear} Mandli</h1>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export default ThankYou