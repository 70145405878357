import React, { useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import './FeedBack.css'
import { ToastContainer, toast } from 'react-toastify';
import { AddFeedBack } from '../../Api';
import axios from 'axios';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box'
import CloseIcon from '@mui/icons-material/Close';
import { getToken } from '../../Helper/Token';

const toastStyle = {
  position: "bottom-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
}

const style = {
  position: 'absolute',
  top: '50%',
  right: '0',
  left: '50%',
  height: '400px',
  bottom: '0',
  width: '35%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  BorderRadius: '5px'
  // p: '1.5rem',
};

function FeedBack() {
  const [validated, setValidated] = useState(false);

  const [showFeedback, SetshowFeedback] = useState(false);
  const handleOpenEshowFeedback = () => SetshowFeedback(true);
  const handleCloseshowFeedback = () => SetshowFeedback(false);

  const [Feedbackk, SetFeedBack] = useState({
    Rating: "",
    Email: "",
    Name: "",
    FeatureFeedback: "",
  })
  const handleChange = (e, data) => {
    SetFeedBack({
      ...Feedbackk,
      [e.target.name]: e.target.value
    })
    //   if (data == "file") {
    //     const radio = e.target.radio;
    //     SetSupoortTicket({
    //         ...suportTicket,
    //         [e.target.value]: radio,
    //     })
    // }

  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    else {
      const FeedbackGen = new FormData()
      FeedbackGen.append('Rating', Feedbackk.Rating)
      FeedbackGen.append('Email', Feedbackk.Email)
      FeedbackGen.append('Name', Feedbackk.Name)
      FeedbackGen.append('FeatureFeedback', Feedbackk.FeatureFeedback)

      // axios.post(AddFeedBack, FeedbackGen)
      //   .then((res) => {
      //     toast.success(res.data.message, toastStyle);
      //     handleOpenEshowFeedback()
      //     // navigate("/")
      //   })
      //   .catch((err) => {
      //     toast.error(err.response.data.message, toastStyle);
      //   });
    }
    setValidated(true);
  };

  const [ userDetails , setUserDetails] = useState({})
  const token = getToken()
  const fetchUserDetails = () => {
  }
  useEffect(() => {
    fetchUserDetails();
  },[])

  useEffect(() => {
    SetFeedBack({
      ...Feedbackk,
      Name:userDetails.BusinessName,
      Email:userDetails.Email
    })
  },[userDetails])
  
  return (
    <div className='FeedBack'>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="mb-3">
          <div className='radio-selection-cst'>
            <div>
              <div className="InputGroup-Contact">

                <div><input type="radio" name="Rating" id="one" value="1" autocomplete="off" onChange={(e) => handleChange(e)} />
                  <label className="lb-cnt-services" for="one">1</label>
                </div>

                <div><input type="radio" name="Rating" id="two" value="2" autocomplete="off" onChange={(e) => handleChange(e)} />
                  <label className="lb-cnt-services" for="two">2</label>
                </div>

                <div><input type="radio" name="Rating" id="three" value="3" autocomplete="off" onChange={(e) => handleChange(e)} />
                  <label className="lb-cnt-services" for="three">3</label>
                </div>

                <div><input type="radio" name="Rating" id="four" value="4" autocomplete="off" onChange={(e) => handleChange(e)} />
                  <label className="lb-cnt-services" for="four">4</label>
                </div>

                <div><input type="radio" name="Rating" id="five" value="5" autocomplete="off" onChange={(e) => handleChange(e)} />
                  <label className="lb-cnt-services" for="five">5</label>
                </div>
              </div>
            </div>

          </div>
        </Row>

        <Row className="mb-5">
          <Form.Group as={Col} controlId="validationCustom03">
            <div className='extra-text'>0 - Extremly Unlikely</div>
          </Form.Group>
          <Form.Group as={Col} controlId="validationCustom03">
            <div className='extra-text'>5 - Extremly Likely</div>
          </Form.Group>
        </Row>

        <Row className="mb-4">
          <Form.Group as={Col} controlId="validationCustom01">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" placeholder="Enter Full Name" onChange={(e) => handleChange(e)} value={Feedbackk.Name} name='Name' required />
            <Form.Control.Feedback type="invalid">
              Please Enter Full Name.
            </Form.Control.Feedback>
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="validationCustom02">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" placeholder="Enter email" name='Email' value={Feedbackk.Email} onChange={(e) => handleChange(e)} required />
            <Form.Control.Feedback type="invalid">
              Please Enter Email.
            </Form.Control.Feedback>
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
        </Row>


        <Row className="mb-4">
          <Form.Group className="" controlId="validationCustom04">
            <Form.Label>What feature can we add to improve?</Form.Label>
            <Form.Control as="textarea" rows={3} placeholder="We'd love to here your suggetions" onChange={(e) => handleChange(e)} name='FeatureFeedback' required />
            <Form.Control.Feedback type="invalid">
              Please Enter your suggetions.
            </Form.Control.Feedback>
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Button variant="primary" className='common-btn mb-3 support-submit' type="submit">
          <span>Submit</span>
        </Button>
      </Form>

      <Modal open={showFeedback} onClose={handleCloseshowFeedback} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
        <Box className="quotation-view x-support" sx={style}>
          <div className='quotationBody ex-lgbt-fr-support'>
            <div className="quotationHeader extra-headerbtn">
              <div className="quotationBtn x-support-btn">
                <CloseIcon className='CloseIcon' onClick={handleCloseshowFeedback} />
              </div>
            </div>
            <div className="main-container">
              <div className="check-container">
                <div className="check-background">
                  <svg viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M7 25L27.3077 44L58.5 7"
                      stroke="white"
                      strokeWidth="13"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className="check-shadow"></div>
              </div>
            </div>
            <h1 className='succes-supoort-btn x-feedback'>Thank you for your valuable feedback!</h1>
            <p className='pera-support-btn x-feedback'>Your feedback is like a guiding light that helps us navigate and enhance our services. Together, we can achieve greatness!</p>
          </div>
        </Box>
      </Modal>


      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

    </div>
  )
}

export default FeedBack