import React, { useEffect } from 'react'
import './About.css'
import img1 from '../../Images/Mandli/shruti.jpeg'
import img2 from '../../Images/Mandli/kavan.jpg'
import img3 from '../../Images/Mandli/Sagar-Vadher.jpeg'
import img4 from '../../Images/Mandli/Maharshi-Patel.jpeg'
import img5 from '../../Images/Mandli/Karan-Makwana.jpg'
import Aboutbnr from '../../Images/Mandli/CEPT-about.png'

function About() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <section className='About-Us buyNow'>

            <div className='inner-banner'>
                <div className="container">
                    <h2>about us</h2>
                    <div className='about_blocks'>
                        <h1><span>C</span>OMMON <span>E</span>VENTS <span>P</span>LANNING <span>T</span>EAM
                        </h1>
                    </div>
                </div>
            </div>

            <div className="main About">
                <div className="container">
                    <div className='about_discriptions'>
                        <p>Mandli is a design-first event organisation, that hosts the most awaited festival experience in Gujarat - Navratri. The initiative of a group of friends, and now CEPT alumni, commemorates the love, passion and zeal for garba into a shared and immersive ecosystem</p>
                        <p>At the core, our mission was to infuse the power of design while taking the essence of the Gujarat's culture of Sheri- style garba at its foundation. And there we had, Mandli.</p>
                        <p>A Designer's dream and a Khelaiya's most desired, Mandli stands as a perfect blend. A space so thoughtfully curated with various design elements categorised as installations - from the entry dome (experiential) to different set ups spread across our campus.</p>
                        <p>Mandli is not just a garba it's an affair of the mind, the heart, the body and most importantly of the soul.</p>
                    </div>


                    <div className='about_discriptions Mobile'>
                    <div className='about_blocks'>
                            <h1 className='ext-spc-mobile'><span>C</span>OMMON <span>E</span>VENTS <span>P</span>LANNING <span>T</span>EAM
                            </h1>
                        </div>
                        <p>Mandli is a design-first event organisation, that hosts the most awaited festival experience in Gujarat - Navratri. The initiative of a group of friends, and now CEPT alumni, commemorates the love, passion and zeal for garba into a shared and immersive ecosystem.</p>
                        <p>At the core, our mission was to infuse the power of design while taking the essence of the Gujarat's culture of Sheri- style garba at its foundation. And there we had, Mandli.</p>
                        <p>A Designer's dream and a Khelaiya's most desired, Mandli stands as a perfect blend. A space so thoughtfully curated with various design elements categorised as installations - from the entry dome (experiential) to different set ups spread across our campus.</p>
                        <p>Mandli is not just a garba it's an affair of the mind, the heart, the body and most importantly of the soul.</p>

                    </div>

                    <div className='About_team_block'>
                        <div className='team_mambers'>
                            <img src={img1} alt="" />
                            <h1>Drashti Patel</h1>
                            <p>CEPT SID ‘16</p>
                        </div>

                        <div className='team_mambers'>
                            <img src={img2} alt="" />
                            <h1>Kavan Berawala</h1>
                            <p>B. ARCH ‘14</p>
                        </div>


                        <div className='team_mambers'>
                            <img src={img3} alt="" />
                            <h1>Sagar Vadher</h1>
                            <p>B. ARCH ‘11</p>
                        </div>

                        <div className='team_mambers'>
                            <img src={img4} alt="" />
                            <h1>Maharshi Patel</h1>
                            <p>CEPT SBST ‘20</p>
                        </div>

                        <div className='team_mambers'>
                            <img src={img5} alt="" />
                            <h1>Karan Makwana</h1>
                            <p>B. ARCH ‘12</p>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default About