import React, { useState } from "react";
import Slider from "react-slick";
import Modal from "react-modal";
import './ImageSlider.css'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ImagePopup from "./ImagePopUp";

const ImageSlider = ({ images, slidesToShowDesktop, slidesToShowTablet, slidesToShowMobile }) => {

    const settings = {
        dots: false,
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: slidesToShowDesktop,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: slidesToShowTablet,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: slidesToShowMobile,
                    slidesToScroll: 1,
                },
            },
        ],
    };


    return (
        <div className="image-slider-container">
            <Slider {...settings}>
                {images.map((image, index) => (
                    <div key={index}>
                        <img
                            src={image}
                            alt={`Slide ${index}`}
                        />
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default ImageSlider;
