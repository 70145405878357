import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'

function TermsandConditions() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (

        <>
            <section className='Privacy-Policys buyNow'>
                <div className='inner-banner'>
                    <div className="container">
                        <h2>Terms & Conditions</h2>
                    </div>
                </div>
            </section>

            <div className='PrivacyPolicy'>
                <section className='section PrivacyPolicy' id='privacy'>
                    <div className='container'>
                        <div className='formats-grid'>
                            <div className='inner-box'>
                                <div className='box-content'>
                                    <p>The following agreement captures the terms and condition of ("Agreement"), applicable to users for the use of application and website <NavLink to="https://commoneventsplanningteam.com/">https://commoneventsplanningteam.com/</NavLink> which is developed by <b>Common Events Planning Team</b> offering tech support for organising events.</p>
                                    <p><b>Common Events Planning Team</b> may modify this Agreement from time to time and each modification will be effective when it is posted on the Website. You agree to be bound to any changes to this Agreement through your continued use of the <b>Common Events Planning Team</b>. You will not be notified of any modifications to this Agreement so it is important that you review this Agreement regularly to ensure you are updated as to any changes.</p>
                                    <p><b>Common Events Planning Team</b> in connection with any of the services those (collectively the “Services “) is governed by these terms of use (the ‘Terms’)</p>
                                </div>
                                <div className='box-content'>
                                    <h1>1. DEFINITIONS</h1>
                                    <ul>
                                        <li>Account: The user signs up and creates a unique account to access our services for each construction site.</li>
                                        <li>Company: For the purposes of these Terms, “we”, “our”, “us”, and <b>Common Events Planning Team</b>, providers and operators of the Services.</li>
                                        <li>Personal Data: Any information that directly, indirectly, or in connection with other information — including a Name, number, address — allows for the identification or identity of a natural person.</li>
                                        <li>You: A person or entity that is registered with <b>“Common Events Planning Team”</b> for using the Services (referred as “user”, “consumer”).</li>
                                        <li>Third-party service: Refers to advertisers, contest sponsors, promotional and marketing partners, payment gateways and others who provide our content or whose products or services we think may interest you.</li>
                                    </ul>
                                </div>
                                <div className='box-content'>
                                    <h1>2. SERVICE OFFERED: </h1>
                                    <p><b>“Common Events Planning Team”</b> is a website, developed by <b>Common Events Planning Team</b> for booking passes of various events organized by them. <b>Common Events Planning Team</b> aims to make hassle free experience of booking passes for their specially curated events like Navratri, concerts & other social gathering events. Our website gives user updates on upcoming events, new offers & contests to be played for winning the passes. </p>
                                    <br />
                                    <p>I.	LICENSE:</p>
                                    <ul className='points'>
                                        <li>Subject to your compliance with the terms; Common Events Planning Team grants you a limited non-exclusive, non-sub licensable, revocable, and non-transferable through a user identification (user id) reference provided by the website strictly in accordance with the terms of this Agreement. (i) Access and use services in the connection with your use of the Services; and (ii) Access and use any content information and related events that may be made available through the Services, expressly granted herein are reserved by Common Events Planning Team and its licensors.</li>
                                    </ul>
                                    <p>II.	PRIVACY POLICY </p>
                                    <ul className='points'>
                                        <li>This Privacy Policy applies to our website <NavLink to="https://commoneventsplanningteam.com/">https://commoneventsplanningteam.com/.</NavLink> We are committed to protecting and respecting your policy. We collect your personal information and process your data in accordance with the Information technology Act, 2000.  By accessing or using our Service, users signify that they have read, understood, and agree to our collection, storage, use, and disclosure of your personal information as described in this Privacy Policy. This Privacy Policy applies exclusively to this website with immediate effect and by visiting our website users agree to be bound by this Privacy Policy. This Privacy Policy is binding upon the user on each circumstance when the user visits the application including while registering yourself on application, undertaking any transaction on the application with third party, transacting with <b>Common Events Planning Team.</b></li>
                                    </ul>
                                </div>
                                <div className='box-content'>
                                    <h1>3. USER ACCOUNT </h1>
                                    <p>In consideration of use of the Website, the User represent and confirm that the User is of legal age to enter into a binding contract and is not a person barred from using the Website and/or receiving the Services under the laws of India or other applicable law. Users are required to register themselves on the website in order to purchase passes. The website will ask user to give specific information during registration in order to create an account. User represents that all account information provided by User is true and accurate, and User agrees to update such information as necessary to maintain its truth and accuracy. User shall be solely liable for all use of User’s account, whether or not authorized by User. We recommend you not to disclose password of your email address, online bank transaction and other important credentials to our employees/ personnel, as we do not ask for the same. We recommend that registered users not to share their application’s account password and also to sign out of their account when they have completed their work. This is to ensure that others cannot access Information of the users and correspondence, if the user shares the computer with someone else or is using a computer in a public place.</p>
                                    <p>User acknowledges that in the event of any illegal use of User account, User will contact <b>Common Events Planning Team</b> right away.</p>
                                </div>
                                <div className='box-content'>
                                    <h1>4. PAYMENT METHOD</h1>
                                    <p>All payments in respect of the Website Services shall be made to <b>Common Events Planning Team</b> through the Website using third party payment gateways to process and facilitate the payment.</p>
                                    <p>PAYMENT OPTIONS:  Common Events Planning Team accept debit card, credit card, internet banking or through, UPI, e- prepaid wallet or other payment method partnered through third party payment gateways. such payments must be paid in advance to avail the services as per the users need
                                        We reserve the right at any time to change its prices and billing methods, either immediately upon posting on our Site or by e-mail.
                                    </p>
                                </div>
                                <div className='box-content'>
                                    <h1>5. RESTRICTION </h1>
                                    <p>Users may not use the services for any purpose other than the permitted access of the service. These restrictions apply in relation to all or part of the material on the Website; link to this web site; assume logo or branding or name without our express written consent. Additional restrictions, it's common for Terms of Use to prohibit users from:</p>
                                    <ul>
                                        <li>selling, copying, sublicensing, transferring, or assigning any information, intellectual property, goods, or services provided on the site</li>
                                        <li>Using the site for any illegal purpose.</li>
                                        <li>Altering or modifying another website to falsely imply that it is associated with the company's website,</li>
                                        <li>Users shall not access to services to build a similar competitive website. </li>
                                    </ul>
                                    <p>Any use of the Site in violation of the foregoing violates these Terms of Use and may result in, among other things, termination or suspension of your rights to use the Site.</p>
                                </div>
                                <div className='box-content'>
                                    <h1>6. TERMINATING OUR SERVICES</h1>
                                    <p>Users agree, confirm and acknowledge that we may modify, suspend, disrupt or discontinue the website, any part of the website or the use of the website, whether to all users or to you specifically, at any time with or without notice to the user. Users agree and acknowledge that we will not be liable for any of the aforementioned actions or for any losses or damages that are caused by any of the aforementioned actions. For the removal of any doubt, we may terminate or prevent your use of the website provided by the website at our sole discretion for any reason and for any period of time. Common Events Planning Team reserves the right to close your account if it contains false, misleading or inappropriate information.</p>
                                </div>
                                <div className="box-content">
                                    <h1>7. FEEDBACK</h1>
                                    <p><b>Common Events Planning Team</b> invites you to provide your feedback, suggestions, convey ideas and other submissions (collectively “Views”) on the Website or by letters or e-mails as long as the same is not violating any moral rights and economic rights according to the Indian laws and is in accordance with these Terms.</p>
                                </div>
                                <div className="box-content">
                                    <h1>8. INTELLECTUAL PROPERTY</h1>
                                    <p>Common Events Planning Team and its subsidiaries entire contents, features and functionality (including but not limited to all information, software, text, displays, images and the design, selection and arrangement thereof), are owned by us, its licensors or other providers of such material and are protected by and international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws. The material may not be copied, modified, reproduced, downloaded or distributed in any way, in whole or in part, without the express prior written permission of us, unless and except as is expressly provided in these Terms & Conditions. Any unauthorized use of the material is prohibited. The website belongs entirely to Common Events Planning Team. We grant only that limited license herein to the user. User content is owned by them and Common Events Planning Team takes no ownership interest in it.</p>
                                </div>
                                <div className="box-content">
                                    <h1>9. COPYRIGHT INFRINGEMENT </h1>
                                    <p>Common Events Planning Team respects the intellectual property rights of others. If the user believe that their work has been improperly copied and posted, please provide us with the following information:</p>
                                    <ul>
                                        <li>Name, address, contact number email address and signature of the copyright owner or of the person authorized to act on the user’s behalf; </li>
                                        <li>A description of the copyrighted work that you claim has been infringed;</li>
                                        <li>A description of where the copyrighted work is uploaded on the website/app.</li>
                                        <li>A written statement that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent or the law;</li>
                                        <li>A statement by the user, made under penalty of perjury, that the above information provided in the notice is accurate and that you are the copyright owner or authorized to act on the copyright owner’s behalf.</li>
                                    </ul>
                                    <p>These requirements must be followed to give us sufficient notice of infringement. Send the infringement complaint to the following email address: </p>
                                </div>
                                <div className="box-content">
                                    <h1>10. DISCLAIMER OF WARRANTIES</h1>
                                    <p>Common Events Planning Team makes no representations about the results to be obtained from using the website. The use of the same is at your own risk. The services and the content provided on the website are on an "As is" basis. Common Events Planning Team assumes no responsibility for any error, omission, interruption, deletion, defect, delay in operation or transmission, communications line failure, theft or destruction or unauthorized access to, or alteration of, any User communication or any Content. Common Events Planning Team is not responsible for any problems or technical malfunction of, computer online systems, servers or providers, computer equipment, software, failure of any email or players due to technical problems or traffic congestion on the Internet or on any of the Common Events Planning Team or combination thereof, including any injury or damage to users or to any person’s computer related to or resulting from participation or downloading materials in connection with our website. We will not share personal information with third parties without your permission. While we make every effort to protect the personal information of the User on this site, we do not accept any liability for the security of the information. We shall not be liable for any loss or damage that may occur while you are using the site. Common Events Planning Team strives to protect the User Information, although we cannot ensure the security of Information furnished/transmitted by the users to us. </p>
                                </div>
                                <div className="box-content">
                                    <h1>11. WAIVER AND SEVERABILITY OF TERMS </h1>
                                    <p>The failure of Common Events Planning Team to exercise or enforce any right or provision of this Agreement shall not constitute a waiver of such right or provision. If any provision of this Agreement is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavour to give effect to the parties’ intentions as reflected in the provision, and the other provisions of this Agreement remain in full force and effect.</p>
                                </div>
                                <div className="box-content">
                                    <h1>12. LIMITATION OF LIABILITY</h1>
                                    <p>In no event shall any subsidiary, affiliate, director, officer, employee, owner, or operator of Common Events Planning Team be liable to you or any third party for any direct, indirect, consequential, exemplary, incidental, special or punitive damages, including lost profit damages arising from your use of the services, even if Common Events Planning Team has been advised of the possibility of such damages notwithstanding anything to the contrary contained herein, Common Events Planning Team liability to you for any cause whatsoever and regardless of the form of the action, will at all times be limited to the amount paid by you to Common Events Planning Team for the services during the term of your use. The foregoing limitation of liability shall apply to the fullest extent permitted by law in the applicable jurisdiction. You specifically acknowledge that Common Events Planning Team shall not be liable for user content or for any defamatory, offensive, or illegal conduct of any third party and that the risk of harm or damage from the foregoing rests entirely with you.</p>
                                </div>
                                <div className="box-content">
                                    <h1>13. INDEMNIFICATION </h1>
                                    <p>User shall indemnify and hold harmless Common Events Planning Team, its subsidiaries, associates, consultants, contracted parties, etc., and their respective officers, directors, agents and employees, from any liability, claim or demand, or actions including reasonable attorney's fees, made by any third party or penalty imposed due to or arising out of your breach of these Terms or any policy/document incorporated by reference or your violation of any laws, rules, regulations or the rights of a third party.</p>
                                </div>
                                <div className="box-content">
                                    <h1>14. APPLICABLE LAWS & JURISDICTION</h1>
                                    <p>THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT OF ANY WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.</p>
                                </div>
                                <div className="box-content">
                                    <h1>15. NOTICE OF COMMUNICATION</h1>
                                    <p>In the case of notices and other communications expressly permitted to be given by telephone, all notices and other communications provided for herein shall be in writing and shall be delivered by hand or courier service or through registered post, mailed by certified or registered mail or sent by telecopy, as follows: <br />
                                        <b>SHOP NO-A/2, SHIV GANGA RESIDENCY,</b><br />
                                        <b>OPP.DHARTIVIDHYALAY, GROUND FLOOR GOPAL CHOWK,</b><br />
                                        <b>NAVANARODA, AHMEDABAD, GUJARAT 382350.</b><br />
                                    </p>
                                </div>
                                <div className="box-content">
                                    <h1>16. MISCELLANOUS</h1>
                                    <p>If for any reason a court of competent jurisdiction finds any provision or portion of these Terms & Conditions to be unenforceable, the remainder of these Terms & Conditions will continue in full force and effect. Headings for any section of the Agreement are for reference purposes only and in no way define, limit, construe or describe the scope or extent of such section.</p>
                                </div>
                                <hr className='give-line' />
                                <div className="box-content">
                                    <p>Common Events Planning Team shall have the right to assign its obligations and duties in this Agreement and in any other agreement relating Common Events Planning Team Services to any person or entity. If any provision of this Agreement is held to be invalid or unenforceable, such provision shall be struck out and the remaining provisions of the Agreement shall be enforced.  </p>
                                </div>


                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </>
    )
}

export default TermsandConditions