import React, { useState } from "react";
import "./Formats.css"
import img01 from '../../Images/2022/01.jpg'
import img02 from '../../Images/2022/02.jpg'
import img03 from '../../Images/2022/03.jpg'
import img04 from '../../Images/2022/04.jpg'
import img05 from '../../Images/2022/05.jpg'
import img06 from '../../Images/2022/06.jpg'
import img07 from '../../Images/2022/07.jpg'
import img08 from '../../Images/2022/08.jpg'
import img09 from '../../Images/2022/09.jpg'
import img10 from '../../Images/2022/10.jpg'
import img11 from '../../Images/2022/11.jpg'
import img12 from '../../Images/2022/12.jpg'
import img13 from '../../Images/2022/13.jpg'
import img14 from '../../Images/2022/14.jpg'
import img15 from '../../Images/2022/15.jpg'
import img16 from '../../Images/2022/16.jpg'
import img17 from '../../Images/2022/17.jpg'
import img18 from '../../Images/2022/18.jpg'
import img19 from '../../Images/2022/19.jpg'
import img20 from '../../Images/2022/20.jpg'
import img21 from '../../Images/2022/21.jpg'
import img22 from '../../Images/2022/22.jpg'
import img23 from '../../Images/2022/23.jpg'
import img24 from '../../Images/2022/24.jpg'
import img25 from '../../Images/2022/25.jpg'
import img26 from '../../Images/2022/26.jpg'
import img27 from '../../Images/2022/27.jpg'
import img28 from '../../Images/2022/28.jpg'
import img29 from '../../Images/2022/29.jpg'
import img30 from '../../Images/2022/30.jpg'
import img31 from '../../Images/2022/31.jpg'

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import Masonry from "react-masonry-css";

const images = [
    {
        id: 1,
        original: img01,
    },
    {
        id: 28,
        original: img28,
    },
    {
        id: 2,
        original: img02,
    },
    {
        id: 3,
        original: img03,
    },
    {
        id: 4,
        original: img04,
    },
    {
        id: 5,
        original: img05,
    },
    {
        id: 6,
        original: img06,
    },
    {
        id: 7,
        original: img07,
    },
    {
        id: 8,
        original: img08,
    },
    {
        id: 9,
        original: img09,
    },
    {
        id: 10,
        original: img10,
    },
    {
        id: 11,
        original: img11,
    },
    {
        id: 12,
        original: img12,
    },
    {
        id: 13,
        original: img13,
    },
    {
        id: 14,
        original: img14,
    },
    {
        id: 15,
        original: img15,
    },
    {
        id: 16,
        original: img16,
    },
    {
        id: 17,
        original: img17,
    },
    {
        id: 18,
        original: img18,
    },
    {
        id: 19,
        original: img19,
    },
    {
        id: 20,
        original: img20,
    },
    {
        id: 21,
        original: img21,
    },
    {
        id: 22,
        original: img22,
    },
    {
        id: 23,
        original: img23,
    },
    {
        id: 24,
        original: img24,
    },
    {
        id: 25,
        original: img25,
    },
    {
        id: 26,
        original: img26,
    },
    {
        id: 27,
        original: img27,
    },
    {
        id: 29,
        original: img29,
    },
    {
        id: 30,
        original: img30,
    },
    {
        id: 31,
        original: img31,
    },
];

function Formats() {

    Fancybox.bind("[data-fancybox]", {
        // Your custom options
    });

    const breakpointColumnsObj = {
        default: 3,
        1100: 2,
        700: 2,
    };


    return (
        <div className="formate-block">
            <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
            >
                {images.map((image) => (
                    <div className="galleryColumn" key={image.id}>
                        <a href={image.original} data-fancybox data-caption={image.caption}>
                            <img src={image.original} alt={image.caption} />
                        </a>
                    </div>
                ))}
            </Masonry>
        </div>
        // <div className="formate-block">
        //     {images.map((image) => (
        //         <div className="column" key={image.id}>
        //             <a href={image.original} data-fancybox data-caption={image.caption}>
        //                 <img src={image.original} alt={image.caption} />
        //             </a>
        //         </div>
        //     ))}
        // </div>
    )
}

export default Formats