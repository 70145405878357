import React, { useEffect, useState } from 'react'
import "./Header.css"
import { BsFillEnvelopeFill } from 'react-icons/bs'
import { FaInstagram } from "react-icons/fa";
import { Dropdown, Avatar, Text, Grid, Modal, Button, useModal } from "@nextui-org/react";
import { NavLink, useNavigate } from 'react-router-dom';
import LogoImg from '../../Images/Mandli/mandli-logo.svg'
import { getIsLogin, getToken } from '../../Helper/Token';
import axios from 'axios';
import { CiMail } from "react-icons/ci";
import { BsTicketPerforated } from 'react-icons/bs'
import InstaIcon from '../../Images/Mandli/Instagram.png'
import UserIcon from '../../Images/Mandli2024/MaleUser.svg'
import PersonIcon from '@mui/icons-material/Person';
import { getPatchUserDetails, getUserDetails } from '../../Api';
import { CiYoutube } from "react-icons/ci";
import { CiInstagram } from "react-icons/ci"

const style = {
  position: 'absolute',
  top: '0',
  right: '0',
  bottom: '0',
  width: '50%',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  // p: '1.5rem',
};

function HomeHeader({ isHeaderFixed }) {
  const navigate = useNavigate()

  // Toggle Menu
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  // Get Token
  const token = getToken()
  const isLogin = getIsLogin()

  // Get User Details
  const [userDetails, setUserDetails] = useState({})
  const fetchUserDetails = () => {
    getToken();
    axios.patch(getPatchUserDetails)
      .then((res) => {
        axios.get(`${getUserDetails}/${res.data.user._id}`)
          .then((res) => {
            setUserDetails(res.data.result)
          })
      })
  }
  useEffect(() => {
    fetchUserDetails()
  }, [])

  // Logout 
  const handleLogOut = (e) => {
    e.preventDefault();
    navigate("/")
    setMenuOpen(false)
    localStorage.clear()
    sessionStorage.clear()
  }

  // Sticky Header
  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset >= 50) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // {`main-header ${isSticky ? 'sticky' : ''}`}

  return (
    <div className={`Header`}>

      <div className={`top-header ${isSticky ? 'sticky' : ''}`}>
        <div className="header-top-area-shape"></div>
        <div className='container'>
          <div className='top-content'>
            <div className='social-media' >
              <a href="mailto:commoneventsplanningteam@gmail.com" ><CiMail />commoneventsplanningteam@gmail.com</a>
              <ul>
                <li><a href="https://www.instagram.com/mandli_garba/?hl=en" target='_blank'><CiInstagram /></a></li>
                <li><a href="https://www.youtube.com/@Mandli_Garba" target='_blank'><CiYoutube /></a></li>
              </ul>
            </div>
            <div className='top-right'>
              {
                !isLogin ?
                  <><NavLink to='/login'>Login</NavLink> / <NavLink to='/signup'>Sign Up</NavLink></>
                  :
                  <NavLink to='/profile'><img src={UserIcon} alt="" /> Hello, {userDetails?.name}</NavLink>
              }
            </div>
          </div>
        </div>
      </div>

      <div className={`main-header rest header${isSticky ? 'sticky' : ''} DesktopHeader`}>
        <div className="container">
          <div className='header-main'>
            <div className='userbox-lg-search logo'>
              <NavLink to="/" onClick={() => navigate('/')}><img src={LogoImg} width={"200"} /></NavLink>
            </div>
            <div className='header-menu'>
              <div className='nav-menu'>
                <ul>
                  <li> <NavLink to="/about" >About</NavLink> </li>
                  <li> <NavLink to="/gallery" >Gallery</NavLink> </li>
                  <li> <NavLink to="/support" >Support</NavLink> </li>
                  <li className='button'>
                    <NavLink className="common-btn" to="/buy-now" ><BsTicketPerforated /><span>Book Passes</span></NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className='header-main mobile'>
            <div className='header-menu'>
              <div className='nav-menu'>
                <ul className='p-0'>
                  <li className='button p-0'>
                    <NavLink className="Insta_n-clr" to="https://www.instagram.com/mandli_garba/?hl=en" target='_blank' >
                      <FaInstagram />
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>

            <div className='userbox-lg-search logo'>
              <NavLink to="/" onClick={() => navigate('/')}><img src={LogoImg} width={"200"} /></NavLink>
            </div>

            <div className='header-menu'>
              <button className={menuOpen ? "menu-toggler active" : "menu-toggler"} id='menu-toggler' type="button" onClick={toggleMenu}>
                <span className="line"></span>
                <span className="line"></span>
                <span className="line"></span>
              </button>
              <div className={menuOpen ? "das-header mobile-nav-menu show" : "das-header mobile-nav-menu"} id="nav-menu">
                <div className="logo">
                  <NavLink to="/">
                    <div className="userbox-lg-search logo"><a aria-current="page" className="active" href="/"><img src={LogoImg} width={"150"} /></a></div>
                  </NavLink>
                  <button className={menuOpen ? "menu-toggler active" : "menu-toggler"} id='menu-close' type="button" onClick={closeMenu}>
                    <span className="line"></span>
                    <span className="line"></span>
                    <span className="line"></span>
                  </button>
                </div>
                <div className="mobile-menu">
                  <ul>
                    <li className="contact">
                      <div className='profile-gothrow'>
                        <div className='imge_profile'>
                          <img src={UserIcon} alt="" />
                        </div>
                        {
                          !isLogin ?
                            <div className='profile_text_login_sign'>
                              <h1>YOUR PROFILE</h1>
                              <div className='main-heading m-0'>
                                <NavLink to='/login' className="effect1" data-hover="Login">Login</NavLink> / <NavLink className="effect1" to='/signup' data-hover="Sign Up">Sign Up</NavLink>
                              </div>
                            </div> :
                            <NavLink to="/profile" className='profile_text_login_sign' onClick={() => setMenuOpen(false)}>
                              <h1>Hi, {userDetails?.name}</h1>
                              <p>My Profile / <NavLink onClick={(e) => handleLogOut(e)}>Logout</NavLink></p>
                            </NavLink>
                        }
                      </div>
                    </li>
                    <li className="contact" onClick={() => setMenuOpen(false)}><NavLink to="/">HOME</NavLink></li>
                    <li className="contact" onClick={() => setMenuOpen(false)}><NavLink to="/about">ABOUT</NavLink></li>
                    <li className="contact" onClick={() => setMenuOpen(false)}><NavLink to="/gallery">GALLERY</NavLink></li>
                    <li className="contact" onClick={() => setMenuOpen(false)}><NavLink to="/support">Support</NavLink></li>
                  </ul>
                  <NavLink className="buyNow common-btn mt-3 justify-content-center" to="/buy-now" onClick={() => setMenuOpen(false)} ><BsTicketPerforated /><span>Book Passes</span></NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`main-header header ${isHeaderFixed && window.location.pathname === '/' ? 'fixed' : ''} mobileHeader`}>
        <div className="container">
          <div className='header-main'>
            <div className='userbox-lg-search logo'>
              <NavLink to="/" onClick={() => navigate('/')}><img src={LogoImg} width={"200"} /></NavLink>
            </div>
            <div className='header-menu'>
              <div className='nav-menu'>
                <ul>
                  <li> <NavLink to="/about" >About</NavLink> </li>
                  <li> <NavLink to="/gallery" >Gallery</NavLink> </li>
                  <li> <NavLink to="/support" >Contact</NavLink> </li>
                  <li className='button'>
                    <NavLink className="common-btn" to="/buy-now" ><BsTicketPerforated /><span>Book Passes</span></NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className='header-main mobile'>
            <div className='header-menu'>
              <div className='nav-menu'>
                <ul className='p-0'>
                  <li className='button p-0'>
                    <NavLink className="Insta_n-clr" to="https://www.instagram.com/mandli_garba/?hl=en" target='_blank' >
                      <FaInstagram />
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>

            <div className='userbox-lg-search logo'>
              <NavLink to="/" onClick={() => navigate('/')}><img src={LogoImg} width={"200"} /></NavLink>
            </div>

            <div className='header-menu'>
              <button className={menuOpen ? "menu-toggler active" : "menu-toggler"} id='menu-toggler' type="button" onClick={toggleMenu}>
                <span className="line"></span>
                <span className="line"></span>
                <span className="line"></span>
              </button>
              <div className={menuOpen ? "das-header mobile-nav-menu show" : "das-header mobile-nav-menu"} id="nav-menu">
                <div className="logo">
                  <NavLink to="/">
                    <div className="userbox-lg-search logo"><a aria-current="page" className="active" href="/"><img src={LogoImg} width={"150"} /></a></div>
                  </NavLink>
                  <button className={menuOpen ? "menu-toggler active" : "menu-toggler"} id='menu-close' type="button" onClick={closeMenu}>
                    <span className="line"></span>
                    <span className="line"></span>
                    <span className="line"></span>
                  </button>
                </div>
                <div className="mobile-menu">
                  <ul>
                    <li className="contact">
                      <div className='profile-gothrow'>
                        <div className='imge_profile'>
                          <img src={UserIcon} alt="" />
                        </div>
                        {
                          !isLogin ?
                            <div className='profile_text_login_sign'>
                              <h1>YOUR PROFILE</h1>
                              <div className='main-heading m-0'>
                                <NavLink to='/login' className="effect1" data-hover="Login">Login</NavLink> / <NavLink className="effect1" to='/signup' data-hover="Sign Up">Sign Up</NavLink>
                              </div>
                            </div> :
                            <NavLink to="/profile" className='profile_text_login_sign' onClick={() => setMenuOpen(false)}>
                              <h1>Hi, {userDetails?.name}</h1>
                              <p>My Profile / <NavLink onClick={(e) => handleLogOut(e)}>Logout</NavLink></p>
                            </NavLink>
                        }
                      </div>
                    </li>
                    <li className="contact" onClick={() => setMenuOpen(false)}><NavLink to="/">HOME</NavLink></li>
                    <li className="contact" onClick={() => setMenuOpen(false)}><NavLink to="/about">ABOUT</NavLink></li>
                    <li className="contact" onClick={() => setMenuOpen(false)}><NavLink to="/gallery">GALLERY</NavLink></li>
                    <li className="contact" onClick={() => setMenuOpen(false)}><NavLink to="/support">SUPPORT</NavLink></li>
                  </ul>
                  <NavLink className="buyNow common-btn mt-3 justify-content-center" to="/buy-now" onClick={() => setMenuOpen(false)} ><span>Book Passes</span></NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}



export default HomeHeader 

