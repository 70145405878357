import React from 'react'
import "./UserSidebar.css";
import { RxDashboard } from 'react-icons/rx'
import { TbFileInvoice } from 'react-icons/tb'
import { FiUsers } from 'react-icons/fi'
import { AiOutlineTags } from 'react-icons/ai'
import { MdOutlineLogout } from 'react-icons/md'
import { NavLink } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import PostAddIcon from '@mui/icons-material/PostAdd';
import PeopleIcon from '@mui/icons-material/People';


const routes = [
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   icons: <RxDashboard />,
  //   id: "dashboard",
  //   key: "Dashboard"
  // },
  {
    path: "/profile",
    name: "My Profile",
    icons: <RxDashboard />,
    id: "profile",
    key: "PROFILE"
  },
  {
    path: "/orders",
    name: "Orders",
    icons: <AiOutlineTags />,
    id: "order",
    key: "order"
  },
  // {
  //   path: "/uploadcontent",
  //   name: "Upload Content",
  //   icons: <AiOutlineTags />,
  //   id: "content",
  //   key: "content"
  // },
]


function UserSidebar({ children }) {

  const navigate = useNavigate();

  const handleLogOut = (e) => {
    e.preventDefault();
    localStorage.clear();
    sessionStorage.clear();
    navigate("/")
  }

  return (
    <div className='UserSidebar'>
      <div className='container'>
        <div className="flexted-body">
          <div className="usersidebar ">
            {routes.map((route) => (
              <NavLink className="userlink" to={route.path} key={route.name} id={route.id}  >
                <div className="ussericon">{route.icons}</div>
                <div className="user-link_text">{route.name}</div>
              </NavLink>
            ))}
            <NavLink to="/logout" key="logout" className="userlink" id="logout" onClick={(e) => handleLogOut(e)} >
              <div className="ussericon"><MdOutlineLogout /></div>
              <div className="user-link_text">Log Out</div>
            </NavLink>
          </div>
          <main className='main-wrap-user' > <div className={`userdashboardcnt ${window.location.pathname === '/profile' || window.location.pathname === '/orders' ? "mobileTabs" : ""}`}>{children}</div> </main>
        </div>
      </div>
    </div>
  )
}

export default UserSidebar