import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getAdminToken } from '../../Helper/Token';
import { getAppMemberOrders, getMemberOrders, getMembersList, getOrderList } from '../../Api';
import { Box, Button, Stack } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { formatNumberWithCommas } from '../../Helper/validation';
import { SuperBalls } from '@uiball/loaders';
import { useParams } from 'react-router-dom';
import { Col, Form, Row } from 'react-bootstrap';

function AppMemberOrder() {
    const { id } = useParams()

    const [rows, setRows] = useState([]);
    const columns = [
        { field: 'id', headerName: 'Sr. No.', width: 70 },
        { field: 'day', headerName: 'Day Name', width: 180, },
        { field: 'totalPurchasedTickets', headerName: 'Total Scan Tickets', width: 200, }
    ];


    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [date, setDate] = useState({
        start: "",
        end: ""
    })

    const handleChange = (e) => {
        const { value, name } = e.target;
        if (name === 'start') {
            setStartDate(value)
        } else if (name === "end") {
            setEndDate(value)
        }
    }
    
    const handleApply = (e) => {
        e.preventDefault();
        setDate({
            ...date,
            start: startDate,
            end: endDate
        })
    }
    
    const handleReset = (e) => {
        e.preventDefault();
        setDate({
            ...date,
            start: "",
            end: ""
        })
        setStartDate("")
        setEndDate("")
    }

    const fetchOrderList = async () => {
        axios.get(`${getAppMemberOrders}/${id}?dateStart=${date.start && new Date(date.start).toISOString()}&dateEnd=${date.end && new Date(date.end).toISOString()}`)
            .then((res) => {
                res.data.result.map((ele, i) => {
                    ele.srno = (res.data.result.page * 10) + (i + 1) - 10
                    ele.id = i + 1;

                    ele.totalPurchasedTickets = ele.totalPurchasedTickets || 0
                });
                setRows(res.data.result)
            })
    };

    useEffect(() => {
        fetchOrderList();
    }, [id, date]);

    // Get Member Details
    const [member, setMember] = useState({});
    const fetchMembers = () => {
        getAdminToken();
        axios.get(`${getMembersList}`)
            .then((res) => {
                const temp = res.data.result.docs.find((ele) => {
                    return ele._id === id
                })
                setMember(temp)
            })
    }
    useEffect(() => {
        fetchMembers()
    }, [id])

    return (
        <>
            <section className='adminDashboard'>
                <div className="dashboard-right-header fr05s-usr-quotsn05s">
                    <div className="left-text">
                        <h4>{member.name}'s Orders</h4>
                    </div>
                </div>

                <div className="dashboard-right-header fr05s-usr-quotsn05s">
                    <div className="left-text">
                        <Form>
                            <Row className="mandli-row mb-3 gap-3">
                                <Form.Group as={Col} controlId="form-control-product">
                                    <Form.Label>Start Date</Form.Label>
                                    <Form.Control type="datetime-local" placeholder="Start Sale Date" value={startDate} name="start" onChange={(e) => handleChange(e)} />
                                </Form.Group>

                                <Form.Group as={Col} controlId="form-control-product">
                                    <Form.Label>End Date</Form.Label>
                                    <Form.Control type="datetime-local" placeholder="End Sale Date" value={endDate} name="end" onChange={(e) => handleChange(e)} />
                                </Form.Group>

                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="form-control-product">
                                    <Button className="common-btn me-3" onClick={(e) => handleApply(e)}>Apply</Button>
                                    <Button className="common-btn m-0" onClick={(e) => handleReset(e)}>Clear</Button>
                                </Form.Group>
                            </Row>
                        </Form>
                    </div>
                </div>

                <div className="ticketsContainer">
                    <Box sx={{ width: '100%' }}>
                        <DataGrid
                            autoHeight {...rows}
                            rows={rows}
                            columns={columns}
                            disableSelectionOnClick
                            pagination={false}
                            components={{
                                NoRowsOverlay: () => (
                                    <Stack height="100%" alignItems="center" justifyContent="center">
                                        <SuperBalls size={45} speed={1.4} color="var(--primary)" />
                                    </Stack>
                                )
                            }}
                        />
                    </Box>
                </div>
            </section>
        </>
    );
}
export default AppMemberOrder