import React, { useEffect, useState } from 'react';
import { CiSearch } from 'react-icons/ci';
import { GoPersonAdd } from 'react-icons/go';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import axios from 'axios';
import { getAdminToken } from '../../Helper/Token';
import { getCreatePhase, getEditPhase, getEnableDisablePhase,getPhaseList } from '../../Api';
import { Box, Button, FormControlLabel, IconButton, Stack, Switch } from '@mui/material';
import { Col, Modal, Row, Text, useModal } from '@nextui-org/react';
import Form from 'react-bootstrap/Form';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DataGrid } from '@mui/x-data-grid';
import { SuperBalls } from '@uiball/loaders';


const toastStyle = {
  position: "bottom-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
}


function Phases() {

  const columns = [
    { field: 'id', headerName: 'Id', width: 70 },
    { field: 'PhaseName', headerName: 'Name', width: 300, },
    {
      field: "Status", headerName: "Status", sortable: false, width: 100,
      renderCell: (prevents) => {
        return (
          <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
            <Switch
              defaultSelected
              color="success"
              checked={prevents.row.flag === 1}
              id={prevents.row._id}
              onClick={() => handleOpenEnable(prevents.row)}
            ></Switch>
          </div>
        );
      }
    },
    {
      field: "actions", headerName: "Action", sortable: false, width: 200,
      renderCell: (prevents) => {
        return (
          <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
            <MatEdit index={prevents.row} />
          </div>
        );
      }
    }
  ];

  // Ticket Enable Disab
  const handleEnableDisable = (e, status, id) => {
    let newStatus = {
      flag: 0
    };
    if (status === 1) {
      newStatus.flag = 3;
    } else if (status === 3) {
      newStatus.flag = 1;
    }
    getAdminToken();
    axios.put(`${getEnableDisablePhase}/${id}`, newStatus)
      .then((res) => {
        fetchPhases();
        setPhaseEnableDisable(false);
        toast.success(res.data.message, toastStyle);
      })
      .catch((err) => {
        toast.error(err.response.data.message, toastStyle);
      })
  }

  const MatEdit = ({ index }) => {
    const handleEditClick = (e) => {
      openEdit();
      setEditData({
        ...editData,
        phaseName: index.PhaseName,
        PhaseName: index.PhaseName,
        _id: index._id
      })
    }

    return (
      <FormControlLabel
        control={
          <IconButton aria-label="add an alarm" className='dyFlextIcon' onClick={(e) => handleEditClick(e)}>
            <ModeEditOutlineOutlinedIcon />
          </IconButton>
        }
      />
    );
  };

  // handle Open Enable 
  const [phaseStatusData, setPhaseStatusData] = useState()
  const handleOpenEnable = (e) => {
    setPhaseStatusData(e)
    setPhaseEnableDisable(true)
  }

  // Search
  const [search, setSearch] = useState("")
  const handleSearch = (e) => {
    setSearch(e.target.value)
  }

  // Pagination
  const [pageState, setPageState] = useState({
    total: 0,
    totalPages: 0,
    page: 1,
    pageSize: 10
  })
  const handlePageChange = (selectedPage) => {
    setPageState((prevState) => ({ ...prevState, page: selectedPage }));
  };
  const ResultInfo = () => {
    const start = (pageState.page - 1) * pageState.pageSize + 1;
    const end = Math.min(start + pageState.pageSize - 1, pageState.total);

    return (
      <div className="result-info">
        Showing <span>{start}-{end}</span> of <span>{pageState.total}</span> Members
      </div>
    );
  };

  // Get All Members List
  const [rows, setRows] = useState([])
  const fetchPhases = () => {
    getAdminToken();
    axios.get(`${getPhaseList}?search=${search}&page=${pageState.page}&limit=${pageState.pageSize}`)
      .then((res) => {
        res.data.result.map((ele, i) => {
          ele.id = i + 1;
        });
        setRows(res.data.result)
      })
  }
  useEffect(() => {
    fetchPhases()
  }, [search, pageState.page, pageState.pageSize])

  // Add Edit Tickets Popup
  const { setVisible: setProductEditModalVisible, bindings: addTicketModalBindings } = useModal();
  const { setVisible: setTicketEditModalVisible, bindings: editTicketModalBindings } = useModal();
  const { setVisible: setPhaseEnableDisable, bindings: phaseEnableDisableBindings } = useModal();

  const openAdd = () => setProductEditModalVisible(true)
  const closeAdd = () => setProductEditModalVisible(false)

  const openEdit = () => setTicketEditModalVisible(true)
  const closeEdit = (e) => {
    e.preventDefault();
    setTicketEditModalVisible(false)
    setEditData({})
  }

  // Add new ticket
  const [newData, setNewData] = useState({
    phaseName: ""
  })

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewData({
      ...newData,
      [name]: value
    })
  }

  const handleAddPhase = (e) => {
    e.preventDefault();
    getAdminToken();
    axios.post(getCreatePhase, newData)
      .then((res) => {
        closeAdd();
        toast.success(res.data.message, toastStyle);
        fetchPhases();
      })
      .catch((err) => {
        toast.error(err.response.data.message, toastStyle);
      })
  }

  // Edit Ticket
  const [editData, setEditData] = useState({})

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditData({
      ...editData,
      PhaseName: value,
      phaseName: value
    })
  }

  const handleEditPhase = (e) => {
    e.preventDefault();
    getAdminToken();
    axios.put(`${getEditPhase}/${editData._id}`, editData)
      .then((res) => {
        closeEdit(e);
        fetchPhases();
        toast.success(res.data.message, toastStyle);
      })
      .catch((err) => {
        toast.error(err.response.data.message, toastStyle);
      })
  }


  return (
    <>
      <section className='adminDashboard'>
        <div className="dashboard-right-header fr05s-usr-quotsn05s">
          <div className="left-text">
            <h4>Phases </h4>
            <p className="m-0">Here is all Phases
            </p>
          </div>
        </div>

        <div className='filter-Search'>
          <div className='filter-block'>
            <div className='qutrowftr08s'>
              <div className='create-block'>
                <Button className='usdercmntbtn common-btn' onClick={openAdd}><GoPersonAdd />Add Phase</Button>
              </div>
            </div>
          </div>
          <div className="search-box form-item position-relative">
            <Form.Control type="text" placeholder="Search.." name="seach" className="search-input w-100" onChange={(e) => handleSearch(e)} />
            <CiSearch />
          </div>
        </div>

        <div className="ticketsContainer">
          <Box sx={{ width: '100%' }}>
            <DataGrid
              autoHeight {...rows}
              rows={rows}
              columns={columns}
              pageSize={5}
              disableSelectionOnClick
              pagination={false}
              components={{
                NoRowsOverlay: () => (
                  <Stack height="100%" alignItems="center" justifyContent="center">
                    <SuperBalls size={45} speed={1.4} color="var(--primary)" />
                  </Stack>
                )
              }}
            />
          </Box>
        </div>
      </section>

      {/* Add Ticket Popup */}
      <Modal scroll width="600px" closeButton aria-labelledby="modal-title" aria-describedby="modal-description" {...addTicketModalBindings} >
        <Modal.Header justify='start'>
          <Text id="modal-title" size={18}>
            Add New Phase
          </Text>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3 gap-3">
            <Form.Group as={Col} className="form-control-product" controlId="formBasicImage">
              <Form.Label>Name</Form.Label>
              <Form.Control className='w-100' type="text" placeholder="Phase Name" name="phaseName" onChange={(e) => handleChange(e)} />
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button auto flat className='modelClose' onClick={() => closeAdd()}>
            Cancel
          </Button>
          <Button auto className='modelSubmit common-btn' onClick={(e) => handleAddPhase(e)}>
            Add Phase
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit Ticket Popup */}
      <Modal scroll width="600px" closeButton aria-labelledby="modal-title" aria-describedby="modal-description" {...editTicketModalBindings} >
        <Modal.Header justify='start'>
          <Text id="modal-title" size={18}>
            Edit Ticket
          </Text>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3 gap-3">
            <Form.Group as={Col} className="form-control-product" controlId="formBasicImage">
              <Form.Label>Name</Form.Label>
              <Form.Control className='w-100' type="text" placeholder="Phase Name" value={editData.PhaseName} name="phaseName" onChange={(e) => handleEditChange(e)} />
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button auto flat className='modelClose' onClick={(e) => closeEdit(e)}>
            Cancel
          </Button>
          <Button auto className='modelSubmit common-btn' onClick={(e) => handleEditPhase(e)}>
            Edit Phase
          </Button>
        </Modal.Footer>
      </Modal >

      {/* Enable Disable Popup */}
      <Modal scroll width="600px" closeButton aria-labelledby="modal-title" aria-describedby="modal-description" {...phaseEnableDisableBindings} >
        <Modal.Header justify='start'>
          <Text id="modal-title" size={18}>
            Are You <Text b id="modal-title" size={18}> Sure!</Text>
          </Text>
        </Modal.Header>
        <Modal.Body className='deleteModel'>
          <Row className="mb-3">
            <h2>You want to {phaseStatusData?.flag === 1 ? "Disable" : "Enable"} <strong style={{ color: "var(--peimary)" }}>{phaseStatusData?.PhaseName}</strong>?</h2>
            {
              phaseStatusData?.flag === 1 ?
                <p>All tickets associated with this phase will disable if you disable this phase.<br />
                  User can't Access tickets associated with this phase anymore if you disable!</p> :
                <p>Enabling this phase will re-enable all associated tickets.<br />
                  Users can access all tickets associated with this phase.</p>
            }
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button auto flat className='modelClose' onClick={() => setPhaseEnableDisable(false)}>
            Cancel
          </Button>
          <Button auto className='modelSubmit common-btn' onClick={(e) => handleEnableDisable(e, phaseStatusData.flag, phaseStatusData._id)}>
            Yes, {phaseStatusData?.flag === 1 ? "Disable" : "Enable"} Phase
          </Button>
        </Modal.Footer>
      </Modal >

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}
export default Phases