import React from 'react'
import './Error.css'
import wheel from '../../Images/wheel.png'
import { NavLink, useNavigate } from 'react-router-dom'

function Error() {
    const navigate = useNavigate();
    return (
        <div className='comming-soon error'>
            <div class="mandli">
                <h1>Mand<span>li</span></h1>
                <p>404 Not Found</p>
                <NavLink className="button-86 cst-font-size" to="/buy-now" onClick={() => navigate(-1)} ><span>Go Back</span></NavLink>
            </div>
            <div class="wheel">
                <img src={wheel} alt="Animation" />
            </div>
        </div>
    )
}

export default Error