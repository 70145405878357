import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import { DataGrid } from '@mui/x-data-grid'
import { FormControlLabel, IconButton } from '@material-ui/core';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';
import { getAdminDetails, getOrderList, getdownloadCsv } from '../../Api'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import 'react-toastify/dist/ReactToastify.css';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { GoPersonAdd } from 'react-icons/go';
import { getAdminToken } from '../../Helper/Token';
import { BiExport } from 'react-icons/bi'
import { CiSearch } from 'react-icons/ci'
import Form from 'react-bootstrap/Form';
import { Pagination } from '@nextui-org/react'
import AddIcon from '@mui/icons-material/Add';
import FIlterIcon from '../../Images/Mandli/Filter.png'
import Popover from '@mui/material/Popover';
import { Scrollbar } from 'react-scrollbars-custom';
import { DateRangePicker } from 'react-date-range';
import { addDays, startOfDay } from 'date-fns';
import { formatNumberWithCommas } from '../../Helper/validation';

const toastStyle = {
  position: "bottom-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
}

const secondmodel = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const style = {
  position: 'absolute',
  top: '0',
  right: '0',
  bottom: '0',
  width: '50%',
  bgcolor: 'background.paper',
  boxShadow: 24,
};

function StudentOrders() {

  // All Filtration 
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection'
    }
  ]);

  useEffect(() => {
    let sday = new Date(state[0].startDate).getDate();
    let smonth = new Date(state[0].startDate).getMonth() + 1;
    let syear = new Date(state[0].startDate).getFullYear();
    if (sday < 10) sday = "0" + sday;
    if (smonth < 10) smonth = "0" + smonth;
    const startDate = `${smonth}/${sday}/${syear}`;
    setDateStart(startDate)

    let eday = new Date(state[0].endDate).getDate();
    let emonth = new Date(state[0].endDate).getMonth() + 1;
    let eyear = new Date(state[0].endDate).getFullYear();
    if (eday < 10) eday = "0" + eday;
    if (emonth < 10) emonth = "0" + emonth;
    const endDate = `${emonth}/${eday}/${eyear}`;
    setDateEnd(endDate)
  }, [state])

  const [filter, setFilter] = useState({
    paymentType: "",
    dateStart: "",
    dateEnd: "",
    PaymentStatus: "",
    memberId: ""
  })

  const [FilterActive, setFilterActive] = useState([]);
  const [dateStart, setDateStart] = useState("")
  const [dateEnd, setDateEnd] = useState("")

  const [isChanged, setIsChanged] = useState(false)
  const handleDateState = (e) => {
    setIsChanged(true)
    setState([e.selection])
  }

  const handleStatusFilter = (e) => {
    const { value } = e.target;
    if (FilterActive.includes(Number(value))) {
      setFilterActive(FilterActive.filter(item => item !== Number(value)));
    } else {
      setFilterActive([...FilterActive, Number(value)]);
    }
  }

  const handleFilter = (e) => {
    e.preventDefault();
    fetchOrderList()
    setFilter({
      ...filter,
      PaymentStatus: FilterActive.join(",")
    })
    setFilter({
      ...filter,
      paymentType: FilterActive.join(",")
    })
    if (isChanged) {
      setFilter({
        ...filter,
        dateStart: dateStart,
        dateEnd: dateEnd
      })
    }
  }

  // Filter Popup
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [FilterActive, setFilterActive] = useState([]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const MatView = ({ index }) => {

    return (
      <FormControlLabel
        control={
          <NavLink className="view_user_order" to={`/admin/orders/vieworder/${index._id}`}>
            <IconButton aria-label="add an alarm" className='dyFlextIcon'>
              <VisibilityOutlinedIcon />
            </IconButton>
          </NavLink>
        }
      />
    );
  };

  function MatDelete({ index, i }) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => { setOpen(false) };

    const handleDeleteItem = (e) => {

    }
    return <FormControlLabel
      control={<>
        <div className='ActionButtons'>
          <IconButton aria-label="add an alarm" onClick={handleOpen} className='dyFlextIcon'>
            <DeleteOutlinedIcon />
          </IconButton>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="delete-data-model" sx={secondmodel}>
              <Typography className="model-icon" id="modal-modal-title" variant="h6" component="h1">
                <DeleteSweepIcon />
              </Typography>
              <Typography className="model-text" id="modal-modal-description" sx={{ mt: 2 }}>
                <h2>Are You Sure! Want to Delete <strong>{index?.QuotationNumber}</strong> Record?</h2>
                <p>Do you really want to delete these records? You can't view this in your list anymore if you delete!</p>
              </Typography>
              <Typography className="model-button">
                <Button variant="contained" className='primaryBtn order_active' onClick={handleClose}  >No, Keep it</Button>
                <Button variant="contained" className='primaryBtn order_active green' onClick={handleDeleteItem} >Yes, Delete it</Button>
              </Typography>
            </Box>
          </Modal>

        </div>
      </>} />;
  }


  // Get User Details
  const [adminDetails, setAdminDetails] = useState({});
  const fetchAdminDetails = () => {
    getAdminToken();
    axios.patch(getAdminDetails)
      .then((res) => {
        setAdminDetails(res.data.admin)
      })
  }
  useEffect(() => {
    fetchAdminDetails();
  }, [])

  // Print Data
  const columns = [
    { field: 'srno', headerName: 'Id', width: 40 },
    { field: 'UserName', headerName: 'User Name', width: 130, },
    { field: 'UserEmail', headerName: 'Email', width: 130, },
    { field: 'ISTDate', headerName: 'Date', width: 200, },
    { field: 'totalTickets', headerName: 'Total Tickets', width: 100, },
    { field: 'PurchasedTickets', headerName: 'Total Visitors', width: 100, },
    // { field: 'PaymentType', headerName: 'Payment Type', width: 110, },
    { field: 'OrderTotal', headerName: 'Total', width: 80, },
    {
      field: "actions", headerName: "Action", sortable: false, width: 75,
      renderCell: (prevents) => {
        return (
          <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
            <MatView index={prevents.row} />
          </div>
        );
      }
    }
  ];

  // Get Order List
  const [rows, setRows] = useState([]);
  const [stats, setStates] = useState({
    totalTickets: 0,
    total24HSale: 0,
    totalSale: 0
  })

  // Pagination
  const [pageState, setPageState] = useState({
    total: 0,
    totalPages: 0,
    page: 1,
    pageSize: 10
  })
  const handlePageChange = (selectedPage) => {
    setPageState((prevState) => ({ ...prevState, page: selectedPage }));
  };
  const ResultInfo = () => {
    const start = (pageState.page - 1) * pageState.pageSize + 1;
    const end = Math.min(start + pageState.pageSize - 1, pageState.total);

    return (
      <div className="result-info">
        Showing <span>{start}-{end}</span> of <span>{pageState.total}</span> Orders
      </div>
    );
  };

  // Search
  const [search, setSearch] = useState("")
  const handleSearch = (e) => {
    setSearch(e.target.value)
  }

  const fetchOrderList = () => {
    getAdminToken();
    axios.get(`${getOrderList}?search=${search}&page=${pageState.page}&limit=${pageState.pageSize}&paymentType=${filter.paymentType}&dateStart=${filter.dateStart}&dateEnd=${filter.dateEnd}&PaymentStatus=2`)
      .then((res) => {
        res.data.result.docs.map((ele, i) => {
          const json = res.data.result;
          setPageState((prevState) => ({ ...prevState, total: json.totalDocs, totalPages: json.totalPages }));

          ele.srno = (res.data.result.page * pageState.pageSize) + (i + 1) - pageState.pageSize
          ele.id = i + 1;

          ele.UserName = ele.UserInfo?.name
          ele.UserEmail = ele.UserInfo?.email
          const timestamp = ele.createdAt;
          const date = new Date(timestamp);
          ele.ISTDate = date.toLocaleString("en-US", { timeZone: "Asia/Kolkata" });

          if (ele.PaymentType.PaymenttypeNumber == 1) {
            ele.PaymentType = "Online"
          } else if (ele.PaymentType == 2) {
            ele.PaymentType = "Cash"
          }

          ele.totalTickets = ele?.TicketInfo?.length
        });
        setRows(res.data.result.docs)
        setStates({
          ...stats,
          totalTickets: res.data.result.totalDocs,
          totalSale: res.data.result.GrandTotal,
          total24HSale: res.data.result.last24HourSaleAmount
        })
      })
  }
  useEffect(() => {
    fetchOrderList();
  }, [search, pageState.page, pageState.pageSize])

  // Download CSV List
  const backendUrl = 'http://';
  const [Export, setExport] = useState([])

  const HandleDownloadCsv = async (Export) => {
    getAdminToken();
    axios.get(getdownloadCsv).then((res) => {
      toast.success(res.data.message, toastStyle);
      setExport(res.data.result)
      const link = document.createElement('a');
      link.href = `${backendUrl}${res.data.result}`; // Set the PDF URL
      link.download = `${backendUrl}${res.data.result}`;
      link.setAttribute('target', "_blank") // Set the desired filename for the downloaded file
      link.setAttribute('download', 'Mandli.pdf')
      link.setAttribute('rel', "noreferrer")
      document.body.appendChild(link);
      link.click();
    }).catch((err) => {
      toast.error(err.response.data.message, toastStyle);
    })
  }

  return (
    <div className='userdashboard'>
      <div className="dashboard-right-header">
        <div className="left-text">
          <h4>Student Orders</h4>
        </div>
        <div className="export_sheet">
          <NavLink to="/admin/students-orders/create-order">
            <Button className='usdercmntbtn common-btn' ><AddIcon />Add Cash Order</Button>
          </NavLink>
        </div>
      </div>

      <div className="userdash-row three">
        <div className="dashboard-stats-box ">
          <div className="single-feature-box sfb01">
            <div className="single-feature-icon">
              <LocalActivityIcon />
            </div>
            <div className="single-feature-data">
              <h6>{stats.totalTickets}</h6>
              <p>Total Orders</p>
            </div>
          </div>
        </div>
        <div className="dashboard-stats-box">
          <div className="single-feature-box bg-danger-50 ">
            <div className="single-feature-icon">
              <CurrencyRupeeIcon />
            </div>
            <div className="single-feature-data">
              <h6>{formatNumberWithCommas(stats.totalSale)}</h6>
              <p>Total Sale</p>
            </div>
          </div>
        </div>
        <div className="dashboard-stats-box">
          <div className="single-feature-box ">
            <div className="single-feature-icon">
              <CurrencyRupeeIcon />
            </div>
            <div className="single-feature-data ">
              <h6>{formatNumberWithCommas(stats.total24HSale)}</h6>
              <p>Last 24 Hours Sale</p>
            </div>
          </div>
        </div>
      </div>

      <div className='filter-Search userList sold_tickets'>
        <div className="search-box form-item position-relative x_sold_tickets">
          <Form.Control type="text" placeholder="Search.." name="seach" className="search-input w-100" onChange={(e) => handleSearch(e)} />
          <CiSearch />
        </div>
        <div className='filter-block'>
          <div className='qutrowftr08s'>
            <div className='create-block'>
              <img src={FIlterIcon} alt="" aria-describedby={id} variant="contained" onClick={handleClick} />
              <div className='fillter' style={{ position: 'relative' }}>
                <Popover id={id} className='popober_model_Css' open={open} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                  left: '204px'
                }}>
                  <div className="section_p_20">
                    <div className="drwdownMenus">
                      <Button className='rst-Btn-Lnk' autoFocus  >
                        Reset
                      </Button>
                      <Button className='aply-Btn-Lnk' onClick={(e) => handleFilter(e)} autoFocus>
                        Apply
                      </Button>
                    </div>
                  </div>
                  <div className='HL'></div>
                  <div className='filter_body'>
                    <div className="short_buy_buttons">
                      <div className="colone">
                        <h1>Sort by</h1>
                        <Form.Select aria-label="Default select example">
                          <option disabled>Newest First</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </Form.Select>
                      </div>
                      <div className="colone">
                        <h1>Sort by Phases</h1>
                        <Form.Select aria-label="Default select example">
                          <option disabled>User</option>
                          <option value="1">Phase 1</option>
                          <option value="2">Early Bird</option>
                          <option value="3">Phase 3</option>
                        </Form.Select>
                      </div>
                    </div>
                    <div className="shot_buy_button">

                    </div>
                  </div>
                  <div className='flex_body short_buy_buttons'>
                    <div className='staut_checked_Details'>
                      <div className=" cst_form_title">
                        <h1>Purchase Type</h1>
                      </div>
                      <Form className='checked_filter'>
                        <div className="mb-3">
                          <Form.Group className="mb-3" controlId="Online">
                            <Form.Check type="checkbox" name='Online' label="Online" value={2} checked={FilterActive.includes(2) ? true : false} onChange={(e) => handleStatusFilter(e)} />
                          </Form.Group>
                        </div>
                        <div className="mb-3">
                          <Form.Group className="mb-3" controlId="Free">
                            <Form.Check type="checkbox" name='Free' label="Free" value={1} checked={FilterActive.includes(1) ? true : false} onChange={(e) => handleStatusFilter(e)} />
                          </Form.Group>
                        </div>
                      </Form>
                    </div>

                    <div className='staut_checked_Details'>
                      <div className=" cst_form_title">
                        <h1>Status</h1>
                      </div>
                      <Form className='checked_filter'>
                        <div className="mb-3">
                          <Form.Group className="mb-3" controlId="Completed">
                            <Form.Check type="checkbox" name='Completed' label="Completed" />
                          </Form.Group>
                        </div>
                        <div className="mb-3">
                          <Form.Group className="mb-3" controlId="Pending">
                            <Form.Check type="checkbox" name='Pending' label="Pending" />
                          </Form.Group>
                        </div>
                      </Form>
                    </div>
                  </div>
                  <div className='staut_checked_Details'>
                    <div className=" cst_form_title">
                      <h1>Ticket</h1>
                    </div>
                    <Form className='checked_filter'>
                      <div className="mb-3">
                        <Form.Group className="mb-3" controlId="Day 1">
                          <Form.Check type="checkbox" name='Day 1' label="Day 1" />
                        </Form.Group>
                      </div>
                      <div className="mb-3">
                        <Form.Group className="mb-3" controlId="Day 2">
                          <Form.Check type="checkbox" name='Day 2' label="Day 2" />
                        </Form.Group>
                      </div>
                      <div className="mb-3">
                        <Form.Group className="mb-3" controlId="Day 3">
                          <Form.Check type="checkbox" name='Day 3' label="Day 3" />
                        </Form.Group>
                      </div>
                      <div className="mb-3">
                        <Form.Group className="mb-3" controlId="Day 4">
                          <Form.Check type="checkbox" name='Day 4' label="Day 4" />
                        </Form.Group>
                      </div>
                      <div className="mb-3">
                        <Form.Group className="mb-3" controlId="Day 5">
                          <Form.Check type="checkbox" name='Day 5' label="Day 5" />
                        </Form.Group>
                      </div>
                    </Form>
                  </div>
                  <div className='HL'></div>
                  <div className="datePiker">
                    {/* <label htmlFor="">Date</label> */}
                    <div style={{ backgroundColor: 'red' }}>
                      <DateRangePicker
                        // onChange={(e) => handleDateState(e)}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={1}
                        ranges={state}
                        direction="horizontal"
                      />
                    </div>
                  </div>
                </Popover>
              </div>
            </div>
          </div>
        </div>
        <div className='filter-block'>
          <div className='qutrowftr08s'>
            <div className='create-block export_sheet'>
              {/* <Button className='usdercmntbtn common-btn' ><GoPersonAdd />Add Cash Tickets</Button> */}
              <Button className='usdercmntbtn common-btn' onClick={HandleDownloadCsv} ><BiExport />EXPORT</Button>
            </div>
          </div>
        </div>

      </div>

      <div className='datagridtableusqot00qat'>
        <Box sx={{ width: '100%' }}>
          <DataGrid
            autoHeight {...rows}
            rows={rows}
            columns={columns}
            pageSize={pageState.pageSize}
            disableSelectionOnClick
            pagination={false}
          />
        </Box>
      </div>
      <div className='pagination-block'>
        <ResultInfo />
        <Pagination
          loop
          color="success"
          total={pageState.totalPages}
          initialPage={1}
          onChange={(page) => handlePageChange(page)}
          currentPage={pageState.page}
        />
      </div>

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  )
}

export default StudentOrders