import React, { useEffect, useState } from 'react';
import AdminHeader from '../Components/AdminPanel/DefultHeader/AdminHeader';
import AdminSidebar from '../Components/AdminPanel/DefultSidebar/AdminSidebar';
import { useNavigate } from 'react-router-dom'
import { getAdminToken, getIsAdminLogin } from '../Helper/Token';
import SiteFooter from '../Components/Footer/SiteFooter';
import { getAdminDetails } from '../Api';
import axios from 'axios';


function AdminLayout({ page }) {
  const navigate = useNavigate();

  const isLogin = getIsAdminLogin();

  useEffect(() => {
    if (!isLogin) {
      navigate("/admin/login")
    }
  }, [isLogin]);

  // Get User Details
  const [adminDetails, setAdminDetails] = useState({});
  const fetchAdminDetails = () => {
    getAdminToken();
    axios.patch(getAdminDetails)
      .then((res) => {
        if (res.status === 401) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/admin/login")
        } else {
          setAdminDetails(res.data.admin);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/admin/login")
        }
      });
  }

  useEffect(() => {
    fetchAdminDetails();
  }, []);


  return (
    <>
      <div className="wrapper-main-cnt-userbodycnt">
        <div className="userbodycnt-Layout">
          <AdminHeader />
          <AdminSidebar>
            {page}
          </AdminSidebar>
          <SiteFooter />
        </div>
      </div>
    </>
  )
}

export default AdminLayout