import React, { useEffect, useState } from 'react';
import { CiSearch } from 'react-icons/ci';
import { AiOutlineEye } from 'react-icons/ai';
import axios from 'axios';
import { getAdminToken } from '../../Helper/Token';
import { AdminGetUserTicketsOrderList } from '../../Api';
import { Box, FormControlLabel, IconButton } from '@mui/material';
import { Pagination } from '@nextui-org/react';
import Form from 'react-bootstrap/Form';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DataGrid } from '@mui/x-data-grid';
import { NavLink, useParams } from 'react-router-dom';

const toastStyle = {
  position: "bottom-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
}

function UserOrders() {

  const { id } = useParams();

  // display Data here
  const columns = [
    { field: 'srno', headerName: 'Id', width: 70 },
    { field: 'name', headerName: 'Name', width: 100, },
    { field: 'email', headerName: 'Email', width: 200, },
    { field: 'phoneNumber', headerName: 'Phone', width: 120, },
    { field: 'PurchasedTickets', headerName: 'Tickets Buy', width: 120, },
    { field: 'OrderTotal', headerName: 'Grand Total', width: 120, },
    {
      field: "actions", headerName: "Action", sortable: false, width: 100,
      renderCell: (prevents) => {
        return (
          <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
            <MatView index={prevents.row._id} />
          </div>
        );
      }
    }
  ];

  // View icon 
  const MatView = ({ index }) => {
    return (
      <FormControlLabel
        control={
          <NavLink to={`/admin/orders/vieworder/${index}`}>
            <IconButton aria-label="add an alarm" className='dyFlextIcon' >
              <AiOutlineEye />
            </IconButton>
          </NavLink>
        }
      />
    );
  };

  // Search
  const [search, setSearch] = useState("")
  const handleSearch = (e) => {
    setSearch(e.target.value)
  }

  // Pagination
  const [pageState, setPageState] = useState({
    total: 0,
    totalPages: 0,
    page: 1,
    pageSize: 10
  })
  const handlePageChange = (selectedPage) => {
    setPageState((prevState) => ({ ...prevState, page: selectedPage }));
  };
  const ResultInfo = () => {
    const start = (pageState.page - 1) * pageState.pageSize + 1;
    const end = Math.min(start + pageState.pageSize - 1, pageState.total);

    return (
      <div className="result-info">
        Showing <span>{start}-{end}</span> of <span>{pageState.total}</span> Orders
      </div>
    );
  };

  const [rows, setRows] = useState([])
  const fetchUsers = () => {
    getAdminToken();
    if (id) {
      axios.get(`${AdminGetUserTicketsOrderList}/${id}?search=${search}&page=${pageState.page}&limit=${pageState.pageSize}`)
        .then((res) => {
          const json = res.data.result;
          setPageState((prevState) => ({ ...prevState, total: json.totalDocs, totalPages: json.totalPages }));
          res.data.result.docs.map((ele, i) => {
            ele.srno = (res.data.result.page * 10) + (i + 1) - 10
            ele.id = i + 1;
            ele.name = ele.UserInfo.name
            ele.email = ele.UserInfo.email
            ele.phoneNumber = ele.UserInfo.phoneNumber
          });
          setRows(res.data.result.docs)
        })
    }
  }
  useEffect(() => {
    fetchUsers()
  }, [id, search, pageState.page, pageState.pageSize])

  return (
    <>
      <section className='adminDashboard'>
        <div className="dashboard-right-header fr05s-usr-quotsn05s">
          <div className="left-text">
            <h4>Order</h4>
            <p className="m-0">View user order details
            </p>
          </div>
          <div className="search-box form-item position-relative">
            <Form.Control type="text" placeholder="Search.." name="seach" className="search-input w-100" onChange={(e) => handleSearch(e)} />
            <CiSearch />
          </div>
        </div>

        <div className="ticketsContainer">
          <Box sx={{ width: '100%' }}>
            <DataGrid
              autoHeight {...rows}
              rows={rows}
              columns={columns}
              pageSize={10}
              disableSelectionOnClick
              pagination={false}
            />
          </Box>
        </div>
        <div className='pagination-block'>
          <ResultInfo />
          <Pagination
            loop
            color="success"
            total={pageState.totalPages}
            initialPage={1}
            onChange={(page) => handlePageChange(page)}
            currentPage={pageState.page}
          />



        </div>
      </section>

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  )
}

export default UserOrders