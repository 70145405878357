import React, { useEffect, useState } from 'react'
import './SoldTickets.css'
import { Button, IconButton } from '@mui/material';
import { CiSearch } from 'react-icons/ci';
import Form from 'react-bootstrap/Form';
import ShareIcon from '@mui/icons-material/Share';
import DownloadIcon from '@mui/icons-material/Download';
import { getAdminToken } from '../../Helper/Token';
import { getPhaseList, getSoldTicketList, getTicketsListAdmin } from '../../Api';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { Modal, Pagination, Row, Text, useModal } from '@nextui-org/react';
import FIlterIcon from '../../Images/Mandli/Filter.png'
import { BsTicketPerforated } from 'react-icons/bs'
import { NavLink } from 'react-router-dom';
import { DateRangePicker } from 'react-date-range';
import { addDays, startOfDay } from 'date-fns';
import { Col } from 'react-bootstrap';

function SoldTickets() {

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection'
    }
  ]);

  useEffect(() => {
    const startDate = new Date(state[0]?.startDate).toISOString();
    setDateStart(startDate);

    const endDate = new Date(state[0]?.endDate).toISOString();
    setDateEnd(endDate);
  }, [state]);

  // Filter
  const [filter, setFilter] = useState({
    Ticketsstatus: "",
    dateStart: "",
    dateEnd: "",
    phase: "",
    Tickets: "",
    TicketsStaus: "",
  })

  // Filter Popup
  const { setVisible: setFilterPopupVisible, bindings: filterPopupBindings } = useModal();
  const handleFilterOpen = () => {
    setFilterPopupVisible(true)
  }
  const handleFilterClose = () => {
    setFilterPopupVisible(false)
  }


  const [dateStart, setDateStart] = useState("")
  const [dateEnd, setDateEnd] = useState("")
  const [phase, setphase] = useState([])
  const [selectedPhase, setSelectedPhase] = useState('')
  const [sortBy, setSortBy] = useState('')
  const [TStatus, setTStatus] = useState('')
  const [Tickets, setTickets] = useState([])

  // Pagination
  const [pageState, setPageState] = useState({
    total: 0,
    totalPages: 0,
    page: 1,
    pageSize: 10
  })
  const handlePageChange = (selectedPage) => {
    setPageState((prevState) => ({ ...prevState, page: selectedPage }));
  };
  const ResultInfo = () => {
    const start = (pageState.page - 1) * pageState.pageSize + 1;
    const end = Math.min(start + pageState.pageSize - 1, pageState.total);

    return (
      <div className="result-info">
        Showing <span>{start}-{end}</span> of <span>{pageState.total}</span> Tickets
      </div>
    );
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageState((prevState) => ({
      ...prevState,
      page: 1,
      pageSize: newPageSize,
    }));
  };

  // Search
  const [search, setSearch] = useState("")
  const handleSearch = (e) => {
    setSearch(e.target.value)
  }

  // Get Phase List
  const fetchphase = () => {
    getAdminToken();
    axios.get(getPhaseList)
      .then((res) => {
        setphase(res.data.result)
      })
  }
  const handlePhaseChnage = (e) => {
    setSelectedPhase(e.target.value)
  }

  const handleSortByChange = (e) => {
    setSortBy(e.target.value)
  }

  const handleStatusChange = (e) => {
    setTStatus(e.target.value)
  }

  // get Selected Tickets
  const fetchTicketsList = () => {
    getAdminToken();
    axios.get(getTicketsListAdmin)
      .then((res) => {
        axios.get(`${getTicketsListAdmin}?limit=${res.data.result.totalDocs}`)
          .then((resp) => {
            setTickets(resp.data.result.docs)
          })
      })
  }

  // Get selected Tickets
  const [ticketFilters, setTicketFilters] = useState([]);
  const handleChecked = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setTicketFilters((prevFilters) => [...prevFilters, value]);
    } else {
      setTicketFilters((prevFilters) => prevFilters.filter((ticket) => ticket !== value));
    }
  };

  // Handle Date Range Filte
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handleChange = (e) => {
    const { value, name } = e.target;
    if (name === 'start') {
      setStartDate(value)
    } else if (name === "end") {
      setEndDate(value)
    }
  }
  // Handle Apply Filter
  const handleFilter = (e) => {
    e.preventDefault();
    fetchTickets()
    handleFilterClose()
    setFilter({
      ...filter,
      Tickets: ticketFilters.join(","),
      sortBy: sortBy,
      phase: selectedPhase,
      TicketsStaus: TStatus,
      dateStart: startDate,
      dateEnd: endDate
    })
  }

  // Handle Reset Filter
  const handleResetFilter = (e) => {
    e.preventDefault();
    fetchTickets();
    setFilter({
      paymentType: "",
      dateStart: "",
      dateEnd: "",
      PaymentStatus: "",
      memberId: "",
      phase: "",
      Tickets: "",
      sortBy: ""
    });
    setTicketFilters([])
    setSelectedPhase('')
    setSortBy('')
    setTStatus('')
    setEndDate('')
    setStartDate('')
  };

  // Get SoldTicketsList
  const [rows, setRows] = useState([])
  const fetchTickets = () => {
    getAdminToken();

    let apiUrl = `${getSoldTicketList}?page=${pageState.page}&limit=${pageState.pageSize}`
    if (search) { apiUrl += `&search=${search}`; }
    if (filter.TicketsStaus) { apiUrl += `&Ticketsstatus=${filter.TicketsStaus}`; }
    if (filter.sortBy) { apiUrl += `&sortBy=${filter.sortBy}`; }
    if (filter.phase) { apiUrl += `&phase=${filter.phase}`; }
    if (filter.Tickets) { apiUrl += `&Tickets=${filter.Tickets}`; }
    if (filter.dateStart) { apiUrl += `&dateStart=${new Date(filter.dateStart).toISOString()}`; }
    if (filter.dateEnd) { apiUrl += `&dateEnd=${new Date(filter.dateEnd).toISOString()}`; }
    if (filter.paymentType) { apiUrl += `&paymentType=${filter.paymentType}`; }
    if (filter.memberId) { apiUrl += `&memberId=${filter.memberId}`; }

    axios.get(`${apiUrl}`)
      .then((res) => {
        const json = res.data.result;
        res.data.result.docs.map((ele, i) => {
          const dateObject = new Date(ele.createdAt);
          const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
          const day = dateObject.getDate();
          const monthIndex = dateObject.getMonth();
          const year = dateObject.getFullYear();
          const hours = dateObject.getHours();
          const minutes = dateObject.getMinutes();
          const amPm = hours >= 12 ? 'PM' : 'AM';
          const hours12 = hours % 12 || 12;

          ele.formattedDateTime = `${day} ${months[monthIndex]}, ${year} ${hours12}:${minutes < 10 ? '0' : ''}${minutes}${amPm}`;
        })

        setPageState((prevState) => ({ ...prevState, total: json.totalDocs, totalPages: json.totalPages }));
        res.data.result.docs.map((ele, i) => {
          ele.srno = (res.data.result.page * 10) + (i + 1) - 10
          ele.id = i + 1;
        });
        setRows(res.data.result.docs)
      })
  }
  useEffect(() => {
    fetchTickets()
    fetchphase()
    fetchTicketsList()
  }, [search, pageState.page, pageState.pageSize, filter])



  // Download PDF
  const handleDownloadClick = (row) => {
    getAdminToken();
    const link = document.createElement('a');
    link.href = `https://${row.PdfUrl}`;
    link.download = `https://${row.PdfUrl}`;
    link.setAttribute('target', "_blank")
    link.setAttribute('download', 'Mandli.pdf')
    link.setAttribute('rel', "noreferrer")

    document.body.appendChild(link);
    link.click();
  };


  // Share PDF
  const handleShareClick = async (row) => {
    try {
      getAdminToken();
      const response = await fetch(`https://${row.PdfUrl}`);
      const buffer = await response.arrayBuffer();

      const pdf = new File([buffer], "Mandli.pdf", { type: "application/pdf" });
      const files = [pdf];

      // Share PDF file if supported.
      if (navigator.canShare({ files })) await navigator.share({ files });
    } catch (error) {
      console.error("Error sharing file:", error);
    }
  };



  return (
    <section className='adminDashboard'>

      <div className="dashboard-right-header fr05s-usr-quotsn05s">
        <div className="left-text">
          <h4>Sold Tickets </h4>
          <p className="m-0">Here is all Sold Tickets List
          </p>
        </div>
        {/* <div className="export_sheet tickets_route">
          <NavLink to="/admin/tickets" className="usdercmntbtn common-btn" >
            <BsTicketPerforated />
            <span>Tickets</span>

          </NavLink>
        </div> */}
      </div>

      <div className='filter-Search sold_tickets'>
        <div className="search-box form-item position-relative x_sold_tickets">
          <Form.Control type="text" placeholder="Search.." name="seach" className="search-input w-100" onChange={(e) => handleSearch(e)} />
          <CiSearch />
        </div>
        <div className='filter-block'>
          <div className='qutrowftr08s'>
            <div className='create-block x_block_imgs'>
              <img src={FIlterIcon} alt="" variant="contained" onClick={handleFilterOpen} />
            </div>
          </div>
        </div>
      </div>

      <div className='sold_tickets_containber'>
        <div className='title_container'>
          <h1>SR No.</h1>
          <h1>Tickets</h1>
          <h1>User Information</h1>
          <h1>Visitors</h1>
          <h1>Status</h1>
          <h1>Action</h1>
        </div>

        {rows.map((row) => (
          <div className='Titckets_layout' key={row._id}>
            <div className='tickets_col'>
              <div className='Tickets_details'>
                <h1>{row.srno}</h1>
              </div>
            </div>
            <div className='tickets_col'>
              <div className='Tickets_details'>
                <h1>{row.TicketInfo.TicketTitle} ({row.PhaseData[0].PhaseName})</h1>
                <span>{row.TicketInfo.Description}</span>
              </div>
            </div>
            <div className='tickets_col'>
              <div className='Tickets_details' style={{ display: 'flex', gap: '0.3rem' }}>
                <span>{row.UserInfo[0]?.name}</span>
                <span>{row.UserInfo[0]?.email}</span>
                <span><b>Date : </b>{row.formattedDateTime}</span>
              </div>
            </div>
            <div className='tickets_col'>
              <div className='Tickets_details purchase_tickets'>
                <h1>{row.TicketInfo.PurchasedTickets}</h1>
              </div>
            </div>
            <div className='tickets_col'>
              <div className='Tickets_details'>
                {row.TicketStatus === 2 ? <span className='used_alert'>Ticket used</span> : <span className='not_used_alert'>Not Used</span>}
              </div>
            </div>
            <div className='tickets_col'>
              <div className='Tickets_details'>
                <div className='action'>
                  <IconButton aria-label="Download PDF" className='dyFlextIcon' target='_blank' onClick={() => handleDownloadClick(row)} download>
                    <DownloadIcon />
                  </IconButton>
                  <IconButton aria-label="Share PDF" className='dyFlextIcon' onClick={() => handleShareClick(row)}>
                    <ShareIcon />
                  </IconButton>
                </div>

              </div>
            </div>
          </div>
        ))}


      </div>

      <div className='pagination-block'>
        <ResultInfo />
        <div className='customPagination'>
          <div className="page-size-select">
            Page Size:{" "}
            <select
              value={pageState.pageSize}
              onChange={(e) => handlePageSizeChange(Number(e.target.value))}
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
          <Pagination
            loop
            color="success"
            total={pageState.totalPages}
            initialPage={1}
            boundaries={1}
            siblings={0}
            onChange={(page) => handlePageChange(page)}
            currentPage={pageState.page}
          />
        </div>
      </div>

      {/* Filter Popup */}
      <Modal scroll width="700px" closeButton aria-labelledby="modal-title" aria-describedby="modal-description" {...filterPopupBindings} >
        <Modal.Header justify='start'>
          <Text id="modal-title" size={18}>
            <b>Filter</b>
          </Text>
        </Modal.Header>
        <Modal.Body>
          <div className='orderFilter'>
            <div className="short_buy_buttons mb-4">
              <div className="colone">
                <h1>Sort By Phases</h1>
                <div className='checkBoxList'>
                  <Form.Check key="all" type="radio" id={`phase-radio-btn`} label="All" value="" checked={selectedPhase === ""} onChange={(e) => handlePhaseChnage(e)} />
                  {phase.map((ele) => (
                    <Form.Check key={ele._id} type="radio" id={`phase-radio-btn-${ele._id}`} label={ele.PhaseName} value={ele._id} checked={selectedPhase === ele._id} onChange={(e) => handlePhaseChnage(e)} />
                  ))}
                </div>
              </div>
            </div>
            <div className="short_buy_buttons mb-4">
              <div className="colone">
                <h1>Sort By Price</h1>
                <div className='checkBoxList'>
                  <Form.Check key="all" type="radio" id={`sortBy-radio-btn`} label="Default" value="" checked={sortBy === ""} onChange={(e) => handleSortByChange(e)} />
                  <Form.Check key="all" type="radio" id={`sortBy-radio-btn-1`} label="High to Low" value="1" checked={sortBy === "1"} onChange={(e) => handleSortByChange(e)} />
                  <Form.Check key="all" type="radio" id={`sortBy-radio-btn-2`} label="Low to High" value="2" checked={sortBy === "2"} onChange={(e) => handleSortByChange(e)} />
                </div>
              </div>
            </div>
            <div className="short_buy_buttons mb-4">
              <div className="colone">
                <h1>Ticket Status</h1>
                <div className='checkBoxList'>
                  <Form.Check key="all" type="radio" id={`ticketStatus-radio-btn`} label="All" value="" checked={TStatus === ""} onChange={(e) => handleStatusChange(e)} />
                  <Form.Check key="all" type="radio" id={`ticketStatus-radio-btn-1`} label="Used" value="2" checked={TStatus === "2"} onChange={(e) => handleStatusChange(e)} />
                  <Form.Check key="all" type="radio" id={`ticketStatus-radio-btn-2`} label="Not Used" value="1" checked={TStatus === "1"} onChange={(e) => handleStatusChange(e)} />
                </div>
              </div>
            </div>
            <div className="datePiker">
              <Form>
                <Row className="mandli-row mb-3 gap-3">
                  <Form.Group as={Col} controlId="form-control-product">
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control type="datetime-local" placeholder="Start Sale Date" value={startDate} name="start" onChange={(e) => handleChange(e)} />
                  </Form.Group>

                  <Form.Group as={Col} controlId="form-control-product">
                    <Form.Label>End Date</Form.Label>
                    <Form.Control type="datetime-local" placeholder="End Sale Date" value={endDate} name="end" onChange={(e) => handleChange(e)} />
                  </Form.Group>
                </Row>
              </Form>
              {/* <DateRangePicker
                onChange={(e) => handleDateState(e)}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={1}
                ranges={state}
                direction="horizontal"
                className='w-100'
              /> */}
            </div>
            <div className='staut_checked_Tickets mb-4'>
              <div className=" cst_form_title">
                <h1>Ticket</h1>
              </div>
              <Row className="mb-3 gap-3">
                <Form.Group className="access-row w-100">
                  {
                    Tickets.map((ele, i) => {
                      return (
                        <Form.Check type="checkbox" id={ele._id} key={ele._id}
                          name={ele.TicketTitle}
                          value={ele.TicketTitle}
                          label={`${ele.TicketTitle} (${ele.PhaseData[0].PhaseName})`}
                          checked={ticketFilters.includes(ele.TicketTitle)}
                          onChange={(e) => handleChecked(e)}
                        />
                      )
                    })
                  }
                </Form.Group>
              </Row>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button auto flat className='modelClose' onClick={(e) => handleResetFilter(e)}>
            Reset
          </Button>
          <Button auto className='modelSubmit common-btn' onClick={(e) => handleFilter(e)}>
            Apply
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

    </section>
  )
}

export default SoldTickets