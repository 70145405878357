export const isValidGST = (gstNumber) => {
    gstNumber = gstNumber.replace(/\s/g, '');

    if (gstNumber.length !== 15) {
        return false;
    }

    var gstRegex = /^([0-9]){2}([A-Z]){5}([0-9]){4}([A-Z]){1}([0-9A-Z]){3}$/;
    if (!gstRegex.test(gstNumber)) {
        return false;
    }

    return true;
};

export const isValidMobileNumber = (mobileNumber) => {
    mobileNumber = mobileNumber.replace(/\s/g, '');

    if (mobileNumber.length !== 10) {
        return false;
    }

    var mobileRegex = /^[6-9]\d{9}$/;

    if (!mobileRegex.test(mobileNumber)) {
        return false;
    }

    return true;
};

export const isValidEmailAddress = (emailAddress) => {
    emailAddress = emailAddress?.trim();

    var emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    return emailRegex.test(emailAddress);
};


export const isValidPincode = (pincode) => {
    pincode = pincode.trim();

    var pincodeRegex = /^[0-9]{6}$/;

    return pincodeRegex.test(pincode);
};
