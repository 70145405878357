import React from "react";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

const ImagePopup = ({ image, onClose }) => {
    return (
        <div className="image-popup">
            <Fancybox
                options={{
                    toolbar: "auto",
                    animationEffect: "fade",
                    animationDuration: 300,
                }}
            >
                <img src={image} alt="Popup" />
            </Fancybox>
            <button onClick={onClose}>Close</button>
        </div>
    );
};

export default ImagePopup;
