// import React from 'react';
import React, { useEffect, useState } from 'react';
import "./Home2024.css"
import "./Home.css"
import { NavLink, useNavigate } from "react-router-dom";
import AnimationImg from '../../Images/Mandli/ANiScroll.png'
import Beforefooter from '../../Images/Mandli/Before-Footer.png'
import Popup from '../../Components/Popup/Popup';
import BannerImage from '../../Images/Mandli/mainBanner.png'
import BannerImageMo from '../../Images/Mandli2024/MainBgchnaged1.svg'
import AboutImg from '../../Images/Mandli/about.png'
import { AiOutlineLink } from 'react-icons/ai';
import img01 from '../../Images/2022/01.jpg'
import img02 from '../../Images/2022/28.jpg'
import img03 from '../../Images/2022/03.jpg'
import img04 from '../../Images/2022/04.jpg'
import img05 from '../../Images/2022/23.jpg'
import img06 from '../../Images/2022/06.jpg'
import img07 from '../../Images/2022/07.jpg'
import img08 from '../../Images/2022/15.jpg'
import img09 from '../../Images/2022/13.jpg'
import img10 from '../../Images/2022/10.jpg'
import arrow from '../../Images/location_arrow.svg'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay, Pagination } from 'swiper/modules';
import Lottie from 'lottie-react';
// import LotiAnimation from '../../Images/Mandli/Lottie/WAVE-Desktop.json' //do not dd
import LotiAnimation from '../../Images/Mandli/Lottie/WAVE-V1-Desktop.json'
import LotiAnimationMobile from '../../Images/Mandli/Lottie/WAVE-V1-Mobile.json'
import ImageSlider from '../../Components/ImageSlider/ImageSlider';
import { MdOutlineArrowOutward } from "react-icons/md";
import { IoChevronDown } from "react-icons/io5";



const items = [
  {
    id: 1,
    imageUrl: img01,
  },
  {
    id: 2,
    imageUrl: img02,
  },
  {
    id: 3,
    imageUrl: img03,
  },
  {
    id: 4,
    imageUrl: img04,
  },
  {
    id: 5,
    imageUrl: img05,
  },
  {
    id: 6,
    imageUrl: img06,
  },
  {
    id: 7,
    imageUrl: img07,
  },
  {
    id: 8,
    imageUrl: img08,
  },
  {
    id: 9,
    imageUrl: img09,
  },
  {
    id: 10,
    imageUrl: img10,
  },
];


function Home2024() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  const [firstSwiper, setFirstSwiper] = useState(null);
  const [secondSwiper, setSecondSwiper] = useState(null);
  const imageCount = 10;
  const images = Array.from({ length: imageCount }, (_, index) => {
    return `demo${index % 2 + 1}`; // Assuming demo1 and demo2 alternate
  });

  const [currentImage, setCurrentImage] = useState(0);
  console.log("first");
  const nextImage = () => {
    setCurrentImage((prevImage) => (prevImage + 1) % images.length);
  };

  const previousImage = () => {
    setCurrentImage(
      (prevImage) => (prevImage - 1 + images.length) % images.length
    );
  };



  const [isHeaderFixed, setIsHeaderFixed] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false); // State to trigger animations on load

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight) {
        setIsHeaderFixed(true);
      } else {
        setIsHeaderFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Trigger animations when the page loads
    setIsLoaded(true);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // faq

  const [activeIndex, setActiveIndex] = useState(0); // Open the first FAQ by default

  const faqs = [
    { question: "Where do I find my purchased passes?", answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores, voluptas." },
    { question: "How to download purchased passes?", answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores, voluptas." },
    { question: "What is the last date for buying the seasonal passes?", answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores, voluptas." },
    { question: "How to avail discount?", answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores, voluptas." },
  ];

  useEffect(() => {
    // Set the height of the initially active FAQ answer
    const answers = document.querySelectorAll('.faq-ans');
    answers.forEach((answer, idx) => {
      if (idx === activeIndex) {
        answer.style.height = answer.scrollHeight + 'px';
      } else {
        answer.style.height = '0';
      }
    });
  }, [activeIndex]); // Run when activeIndex changes

  // Function to toggle the FAQ visibility
  const toggleFAQ = (index) => {
    if (activeIndex === index) {
      // Close the currently active FAQ smoothly
      const answer = document.querySelector(`.faq-ans[data-index="${index}"]`);
      answer.style.height = answer.scrollHeight + 'px'; // Set to current height for smooth transition
      setTimeout(() => {
        answer.style.height = '0'; // Collapse after the height is set
      }, 300); // Match this to your CSS transition duration
      setActiveIndex(null); // Reset activeIndex to null
    } else {
      setActiveIndex(index); // Set the new active index
    }
  };



  return (
    <div className='Home2024'>
      <Popup />


      <section className="section banner hideinmobile" id='banner'>
        <div className='container'>
          <div className='main-heading cst-font-spc-bt banner-content og'>
            <div className='banner-text'>
              <h1 className='titleDesktop pb-0 ind_text_swd'><span>Mandli Garba</span> <br /> </h1>
              <h3 className='ind_text_swd'>Navratri 2024 at its best</h3>
            </div>
          </div>
          <div className="mandlinfo">
            <div className='main-heading'>
              <h3 className="subheading ind_text_swd">dates</h3>
              <p className="clr_change_text ind_text_swd lh-base">3<sup>rd</sup> to 12<sup>th</sup> Oct 2024<br />08:00 PM onwards</p>
            </div>
            <div className='main-heading'>
              <h3 className="subheading ind_text_swd">venue</h3>
              <div className="mxcontetn"><NavLink to=""><p className="clr_change_text ind_text_swd lh-base">Mandli Garba 2024, near Ognaj toll tax, <br />off S.P ring road, Ahmedabad, Gujarat. <MdOutlineArrowOutward style={{ color: 'var(--primaryYellow)' }} /></p></NavLink></div>
            </div>
          </div>
          <div className='main-heading pt-2 new-btn-style'>
            <NavLink className="common-btn white cst-font-size gallry_filed og-font-style" to="/buy-now" ><span>Book Passes</span></NavLink>
          </div>
        </div>
      </section>

      <section className={`section whenWhere-section hideinDesktop`} id='formats'>
        <div className="box-width-block">
          <div className="container pb-0">
            <div className='heading-addon2024'>
              <h1 className="and-fonts h-one clr_change addon2024">Mandli Garba</h1>
              <p>Navratri 2024 at its best</p>
            </div>

          </div>
          <div className='container pb-0'>
            <div className='main-heading cst-font-spc-bt'>
              <h2 className="and-fonts h-one clr_change">when &</h2>
              <h2 className="and-fonts h-two "> where</h2>
            </div>
            <div className='main-heading'>
              <h3 className="subheading">dates</h3>
              <p className="clr_change_text">3<sup>rd</sup> to 12<sup>th</sup> Oct 2024<br />08:00 PM onwards</p>
            </div>
            <div className='main-heading'>
              <h3 className="subheading">venue</h3>
              {/* <p className="clr_change_text"><NavLink to="https://maps.app.goo.gl/cqRpAZUdkpGEBYMU7" target='_blank'>Mandli Garba 2023, SOM Greens, near Amaryllis crossing,<br />Canal Road, Shilaj, Ahmedabad, Gujarat - 380059. <AiOutlineLink /></NavLink></p> */}
              {/* <p className="clr_change_text">Mandli Garba 2024, near Ognaj Toll Tax, <br />off Sardar Patel Ring Road, <br />Ahmedabad, Gujarat 380060</p> */}
              <div className="mxcontetn"><NavLink to=""><p className="clr_change_text ind_text_swd lh-base">Mandli Garba 2024, near Ognaj toll tax, <br />off S.P ring road, Ahmedabad, Gujarat. <MdOutlineArrowOutward style={{ color: 'var(--primaryYellow)' }} /></p></NavLink></div>
            </div>
            <div className='main-heading pt-2 mobile'>
              <NavLink className="common-btn big cst-font-size" to="/buy-now" ><span>Book Passes</span></NavLink>
            </div>
          </div>
        </div>
      </section>

      <section className='section about-section hideinmobile-section' id='Whatismandli'>
        <div className='container'>
          <div className='row'>
            <div className='left'>
              <div className='main-heading what_is_mandli'>
                <h2 className="and-fonts ">what is <span>mand<span>li<sup>&reg;</sup></span></span></h2>
                <p className='ind_text_swd lineheight-spc lh-base'>Mandli is a design-first event that hosts the most awaited Sheri - Style Garba. The initiative of a group of friends, and now CEPT alumni, commemorates the love, passion and zeal for garba into a shared and immersive ecosystem.</p>
                {/* <p className='ind_text_swd lineheight-spc '>A Designer's dream and a Khelaiya's most desired. </p> */}
                <p className='ind_text_swd lineheight-spc '>An experience curated through <br/>Design installations, Dhol & Shehnai<br/> and your love! </p>
                <p className='ind_text_swd lineheight-spc '>Aavo Fari Jodaiye!!</p>
                <div className='main-heading pt-2 new-btn-style pt-4'>
                  <NavLink className="common-btn white cst-font-size gallry_filed og-font-style" to="/about" ><span>Know More</span></NavLink>
                </div>
              </div>
            </div>
            {/* <div className='right'>
              <img src={AboutImg} alt="" />
            </div> */}
          </div>
        </div>
      </section>


      <section className='section formats-section' id='galleryog'>
        <div className='container'>
          <div className="addpsaceIndesktop">
            <div className='main-heading what_is_mandli'>
              <h2 className="and-fonts">glimpses from past</h2>
            </div>
            <div className='image-gallry '>
              <ImageSlider
                images={[img01, img02, img03, img04, img05, img06, img07, img08, img09, img10]}
                slidesToShowDesktop={3}
                slidesToShowTablet={3}
                slidesToShowMobile={1}
              />

            </div>
          </div>
        </div>
        <div className='main-heading mt-5'>
          <div className='container new-btn-style'>
            <NavLink className="common-btn white big cst-font-size gallry_filed og-font-style" to="/gallery" ><span>View Gallery</span></NavLink>
          </div>

        </div>
      </section>


      <section className='faqs-sec'>
        <div className='container'>
          <div className="addpsaceIndesktop">
            <h2 className="and-fonts">Frequently Asked Questions</h2>
            <div className="mobile-faq-container">
              {faqs.map((faq, index) => (
                <div
                  className={`faq-item ${activeIndex === index ? 'active' : ''}`}
                  key={index}
                  onClick={() => toggleFAQ(index)}
                >
                  <div className="faq-question">
                    {faq.question}
                    <span className={`arrow ${activeIndex === index ? 'rotate' : ''}`}>
                      <IoChevronDown/>
                    </span>
                  </div>
                  <div className="faq-ans">
                    {faq.answer}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Home2024
