import React, { useEffect } from 'react'
import './PrivacyPolicy.css'
import { NavLink } from 'react-router-dom'

function PrivacyPolicy() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <section className='Privacy-Policys buyNow'>
                <div className='inner-banner'>
                    <div className="container">
                        <h2>Privacy Policy</h2>
                    </div>
                </div>
            </section>

            <div className='PrivacyPolicy'>
                <section className='section PrivacyPolicy' id='privacy'>
                    <div className='container'>
                        <div className='formats-grid'>
                            <div className='inner-box'>
                                <div className='box-content'>
                                    <h1>INTRODUCTION</h1>
                                    <p>This Privacy Policy applies to our website https://commoneventsplanningteam.com/. By accessing or using website, you signify that you have read, understood, and agree to our collection, storage, use, and disclosure of your personal information as described in this Privacy Policy.</p>
                                </div>
                                <div className='box-content'>
                                    <h1>APPLICABILITY</h1>
                                    <p>This Privacy Policy applies exclusively to this website with immediate effect and by visiting our website you agree to be bound by this Privacy Policy. This Privacy Policy is binding upon you on each circumstance when you visit the website including while registering yourself on website, undertaking any transaction on the website with third party payment gateway.</p>
                                </div>
                                <div className='box-content'>
                                    <h1>Cookie policy</h1>
                                    <p>The visitors of the website give have agreed about our cookie policy with the collection, use, and disclosure of Personal Information if anyone decided to use our website <NavLink to="https://commoneventsplanningteam.com/">https://commoneventsplanningteam.com/</NavLink>. Cookies can either be session cookies or persistent cookies. A session cookie expires automatically when you close your browser. A persistent cookie will remain until it expires or you delete your cookies. Expiration dates are set in the cookies themselves; some may expire after a few minutes while others may expire after multiple years. Cookies placed by the website you’re visiting are called “first party cookies". “First-party cookies”, which are created by us to help make “Common event planning team” work properly and analyses usage.</p>
                                </div>
                                <div className='box-content'>
                                    <h1>COLLECTION OF INFORMATION</h1>
                                    <p>We confirm that we collect that information from you which are required to extend the services available on the website.</p>
                                    <ul typeof='i'>
                                        <li>At the time of signing up and registration with the website, we collect user information including name, email address, phone/mobile number, and postal address.</li>
                                        <li>We also gather and store the user’s usage statistics such as IP addresses, pages viewed, user behaviour pattern, browsing activities, browser software operating system etc. for analysis, which helps us to provide improved experience and value-added services to you.</li>
                                        <li>We retain user provided Information for as long as the Information is required for the purpose of providing services to you or where the same is required for any purpose for which the Information can be lawfully processed or retained as required under any statutory enactments or applicable laws.</li>
                                    </ul>
                                </div>
                                <div className='box-content'>
                                    <h1>USE OF INFORMATION</h1>
                                    <ul typeof='i'>
                                        <li>For the verification of your identity, eligibility, registration and to provide customized services.</li>
                                        <li>Verifying and carrying out financial transactions in relation to payments or transfers made.</li>
                                        <li>For sending communications, notifications and customized mails etc for latest offer promotions.</li>
                                        <li>Carrying out research analytics on users’ behaviour and demographics.</li>
                                    </ul>
                                </div>
                                <div className='box-content'>
                                    <h1>DISCLOSURE OF INFORMATION</h1>
                                    <p>Information we may collect from you may be disclosed and transferred to external service providers who we rely on to provide services to us or to you directly. For instance, information may be shared with;</p>
                                </div>
                                <div className='box-content'>
                                    <h1>Provision of aggregate demographic information to third parties:</h1>
                                    <p>Common Events Planning Team reserves the right to provide aggregate demographic information of its member base to advertisers and others so as to attract pertinent advertisers to advertise on Common Events Planning Team. We do not shared personal information of individual users with advertisers.</p>
                                    <ul typeof='i'>
                                        <li>Government or regulatory or law enforcement agencies, as mandated under statutory enactment, for verification of identity or for prevention, detection, investigation including cyber incidents, prosecution and punishment of offences.</li>
                                        <li>Service provider including but not limited to payment, customer and cloud computing service provider (“Third Party”) engaged for facilitating service requirements of users.</li>
                                        <li>Websites shall keep the Information confidential and secure by using a reasonable degree of care to protect against the loss, misuse and alteration of your personal data.</li>
                                    </ul>
                                </div>
                                <div className='box-content'>
                                    <h1>NETWORK OPERATORS</h1>
                                    <p>The usage of the Site Service may involve the use of third-party telecommunications carriers and service providers (such as the services of the carrier that provides cell phone service to you). These carriers and service providers are not our contractors, and any information collected by a carrier in connection with your use of the Service provided through the Site and/or Platforms is not considered "Personal Information" and is not covered by this Privacy Policy. Common event planning team is not liable for telecommunications carriers or service provider’s acts or omissions.</p>
                                </div>
                                <div className='box-content'>
                                    <h1>REASONABLE PROTECTION OF INFORMATION</h1>
                                    <ul typeof='i'>
                                        <li>We employ commercially reasonable and industry-standard security measures to prevent unauthorized access, maintain data accuracy and ensure proper use of information we receive. These security measures are both electronic as well as physical but at the same time no data transmission over the Internet can be guaranteed to be 100% secure.</li>
                                        <li>We strive to protect the User Information, although we cannot ensure the security of Information furnished/transmitted by the users to us.</li>
                                        <li>We recommend you not to disclose the password of your email address, online bank transaction and other important credentials to our employees/ agents/ affiliates/ personnel, as we do not ask for the same.</li>
                                        <li>We recommend that registered users not share their website’s account password and also to sign out of their account when they have completed their work. This is to ensure that others cannot access Information of the users and correspondence, if the user shares the computer with someone else or is using a computer in a public place.</li>
                                    </ul>
                                </div>
                                <div className='box-content'>
                                    <h1>APPLICABLE LAW AND JURISDICTION</h1>
                                    <p>By accessing this website, these Security and Privacy Policy consummated between you and Common event planning team, and our relationship with you is governed by the laws of India, in the event of any dispute arising in relation to these Security and Privacy Policy, or any dispute arising in relation to the website, whether in contract or tort or otherwise, to submit to the jurisdiction of the courts located at, Gujarat, India for the resolution of all such disputes.</p>
                                    <p>Please also read Terms and Conditions as set out on the website. By accessing the website, it shall be deemed that you have read the Terms and Conditions and this policy and the same are acceptable and binding upon you.</p>
                                </div>
                                <div className='box-content'>
                                    <h1>MISCELLANEOUS</h1>
                                    <p>In case of a conflict in any terms of Privacy Policy, the decision of Common event planning team shall be final and concluding, and you irrevocably withdraw your right to make any claim on this ground.</p>
                                    <p>Please also read Terms and Conditions as set out on the website. By accessing the website, it shall be deemed that you have read the Terms and Conditions and this policy and the same are acceptable and binding upon you.</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </>
    )
}

export default PrivacyPolicy