import React, { useEffect, useState } from 'react';
import './Users.css'
import { CiSearch } from 'react-icons/ci';
import { AiOutlineEye } from 'react-icons/ai';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import axios from 'axios';
import { getAdminToken } from '../../Helper/Token';
import { getAccessList, getAddMember, getAdminCreateTicket, getAdminEditTicket, getDeleteMember, getEditMember, getEnableDisableTicket, getEnableDisableUser, getMembersList, getTicketsList, getUserList } from '../../Api';
import { Box, Button, FormControlLabel, IconButton, Stack } from '@mui/material';
import { Col, Modal, Pagination, Row, Switch, Text, useModal } from '@nextui-org/react';
import Form from 'react-bootstrap/Form';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DataGrid } from '@mui/x-data-grid';
import { NavLink } from 'react-router-dom';
import { SuperBalls } from '@uiball/loaders';


const toastStyle = {
  position: "bottom-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
}


function Users() {

  const columns = [
    { field: 'srno', headerName: 'Id', width: 70 },
    { field: 'name', headerName: 'Name', width: 180, },
    { field: 'email', headerName: 'Email', width: 200, },
    { field: 'phone', headerName: 'Phone', width: 150, },
    { field: 'TotalPass', headerName: 'Tickets Buy', width: 120, },
    {
      field: "Status", headerName: "Status", sortable: false, width: 100,
      renderCell: (prevents) => {
        return (
          <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
            <Switch defaultSelected color="success" checked={prevents.row.flag === 1} onChange={(e) => handleEnableDisable(e, prevents.row.flag, prevents.row._id)}>Success</Switch>
          </div>
        );
      }
    },
    {
      field: "actions", headerName: "Action", sortable: false, width: 200,
      renderCell: (prevents) => {
        return (
          <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
            <MatView index={prevents.row._id} />
          </div>
        );
      }
    }
  ];

  const MatView = ({ index }) => {
    return (
      <FormControlLabel
        control={
          <NavLink to={`/admin/users/orders/${index}`}>
            <IconButton aria-label="add an alarm" className='dyFlextIcon' >
              <AiOutlineEye />
            </IconButton>
          </NavLink>
        }
      />
    );
  };

  // Ticket Enable Disab
  const handleEnableDisable = (e, status, id) => {
    let newStatus = {
      flag: 0
    };
    if (status === 1) {
      newStatus.flag = 3;
    } else if (status === 3) {
      newStatus.flag = 1;
    }
    getAdminToken();
    axios.put(`${getEnableDisableUser}/${id}`, newStatus)
      .then((res) => {
        fetchUsers()
        toast.success(res.data.message, toastStyle);
      })
      .catch((err) => {
        toast.error(err.response.data.message, toastStyle);
      })
  }

  // Search
  const [search, setSearch] = useState("")
  const handleSearch = (e) => {
    setSearch(e.target.value)
  }

  // Pagination
  const [pageState, setPageState] = useState({
    total: 0,
    totalPages: 0,
    page: 1,
    pageSize: 10
  })
  const handlePageChange = (selectedPage) => {
    setPageState((prevState) => ({ ...prevState, page: selectedPage }));
  };
  const ResultInfo = () => {
    const start = (pageState.page - 1) * pageState.pageSize + 1;
    const end = Math.min(start + pageState.pageSize - 1, pageState.total);

    return (
      <div className="result-info">
        Showing <span>{start}-{end}</span> of <span>{pageState.total}</span> Users
      </div>
    );
  };
  const handlePageSizeChange = (newPageSize) => {
    setPageState((prevState) => ({
      ...prevState,
      page: 1,
      pageSize: newPageSize,
    }));
  };

  // Get All User List
  const [rows, setRows] = useState([])
  const fetchUsers = () => {
    getAdminToken();
    axios.get(`${getUserList}?search=${search}&page=${pageState.page}&limit=${pageState.pageSize}`)
      .then((res) => {
        const json = res.data.result;
        setPageState((prevState) => ({ ...prevState, total: json.totalDocs, totalPages: json.totalPages }));
        res.data.result.docs.map((ele, i) => {
          ele.srno = (res.data.result.page * 10) + (i + 1) - 10
          ele.id = i + 1;
        });
        setRows(res.data.result.docs)
      })
  }
  useEffect(() => {
    fetchUsers()
  }, [search, pageState.page, pageState.pageSize])

  return (
    <>
      <section className='adminDashboard'>
        <div className="dashboard-right-header fr05s-usr-quotsn05s">
          <div className="left-text">
            <h4>Users</h4>
            <p className="m-0">Here is all Users List
            </p>
          </div>
          <div className="search-box form-item position-relative">
            <Form.Control type="text" placeholder="Search.." name="seach" className="search-input w-100" onChange={(e) => handleSearch(e)} />
            <CiSearch />
          </div>
        </div>

        <div className="ticketsContainer">
          <Box sx={{ width: '100%' }}>
            <DataGrid
              autoHeight {...rows}
              rows={rows}
              columns={columns}
              pageSize={pageState.pageSize}
              disableSelectionOnClick
              pagination={false}
              components={{
                NoRowsOverlay: () => (
                  <Stack height="100%" alignItems="center" justifyContent="center">
                    <SuperBalls size={45} speed={1.4} color="var(--primary)" />
                  </Stack>
                )
              }}
            />
          </Box>
        </div>
        <div className='pagination-block'>
          <ResultInfo />
          <div className='customPagination'>
            <div className="page-size-select">
              Page Size:{" "}
              <select
                value={pageState.pageSize}
                onChange={(e) => handlePageSizeChange(Number(e.target.value))}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
            <Pagination
              loop
              color="success"
              total={pageState.totalPages}
              initialPage={1}
              boundaries={1}
              siblings={0}
              onChange={(page) => handlePageChange(page)}
              currentPage={pageState.page}
            />
          </div>
        </div>
      </section>

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}
export default Users