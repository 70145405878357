import React, { useEffect, useState } from 'react';
import './Gallery.css'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // Import the styles
import Formats from '../../Components/Formats/Formats';
// import img01 from '../../Images/Mandli/Event/01.jpg'
// import img02 from '../../Images/Mandli/Event/02.jpg'
// import img03 from '../../Images/Mandli/Event/03.jpg'
// import img04 from '../../Images/Mandli/Event/04.jpg'
// import img05 from '../../Images/Mandli/Event/05.jpg'
// import img06 from '../../Images/Mandli/Event/06.jpg'
// import img07 from '../../Images/Mandli/Event/07.jpg'
// import img08 from '../../Images/Mandli/Event/08.jpg'
// import img09 from '../../Images/Mandli/Event/09.jpg'
// import img10 from '../../Images/Mandli/Event/10.jpg'
// import img11 from '../../Images/Mandli/Event/11.jpg'
// import img12 from '../../Images/Mandli/Event/12.jpg'
// import img13 from '../../Images/Mandli/Event/13.jpg'
// import img14 from '../../Images/Mandli/Event/14.jpg'
// import img15 from '../../Images/Mandli/Event/15.jpg'
// import img16 from '../../Images/Mandli/Event/16.jpg'
// import img17 from '../../Images/Mandli/Event/17.jpg'
// import img18 from '../../Images/Mandli/Event/18.jpg'
// import img19 from '../../Images/Mandli/Event/19.jpg'
// import img20 from '../../Images/Mandli/Event/20.jpg'
// import img21 from '../../Images/Mandli/Event/21.jpg'
// import img22 from '../../Images/Mandli/Event/22.jpg'
// import img23 from '../../Images/Mandli/Event/23.jpg'
// import img24 from '../../Images/Mandli/Event/24.jpg'
// import img25 from '../../Images/Mandli/Event/25.jpg'
// import img26 from '../../Images/Mandli/Event/26.jpg'
// import img27 from '../../Images/Mandli/Event/27.jpg'
// import img28 from '../../Images/Mandli/Event/28.jpg'
// import img29 from '../../Images/Mandli/Event/29.jpg'
// import img30 from '../../Images/Mandli/Event/30.jpg'
// import img31 from '../../Images/Mandli/Event/31.jpg'

// const itemsa = [
//     {
//         imageUrl: img01,
//     },
//     {
//         imageUrl: img02,
//     },
//     {
//         imageUrl: img03,
//     },
//     {
//         imageUrl: img04,
//     },
//     {
//         imageUrl: img05,
//     },
//     {
//         imageUrl: img06,
//     },
//     {
//         imageUrl: img07,
//     },
//     {
//         imageUrl: img08,
//     },
//     {
//         imageUrl: img09,
//     },
//     {
//         imageUrl: img10,
//     },
//     {
//         imageUrl: img11,
//     },
//     {
//         imageUrl: img12,
//     },
//     {
//         imageUrl: img13,
//     },
//     {
//         imageUrl: img14,
//     },
//     {
//         imageUrl: img15,
//     },
//     {
//         imageUrl: img16,
//     },
//     {
//         imageUrl: img17,
//     },
//     {
//         imageUrl: img18,
//     },
//     {
//         imageUrl: img19,
//     },
//     {
//         imageUrl: img20,
//     },
//     {
//         imageUrl: img21,
//     },
//     {
//         imageUrl: img22,
//     },
//     {
//         imageUrl: img23,
//     },
//     {
//         imageUrl: img24,
//     },
//     {
//         imageUrl: img25,
//     },
//     {
//         imageUrl: img26,
//     },
//     {
//         imageUrl: img27,
//     },
//     {
//         imageUrl: img28,
//     },
//     {
//         imageUrl: img29,
//     },
//     {
//         imageUrl: img30,
//     },
//     {
//         imageUrl: img31,
//     },
//     // Add more items as needed
// ];

function Gallery() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    // useEffect(() => {
    //     window.scrollTo(0, 0)
    // }, [])

    // // State to manage the lightbox
    // const [lightboxOpen, setLightboxOpen] = useState(false);
    // const [photoIndex, setPhotoIndex] = useState(0);

    // const openLightbox = (index) => {
    //     setPhotoIndex(index);
    //     setLightboxOpen(true);
    // };

    // const closeLightbox = () => {
    //     setLightboxOpen(false);
    // };

    return (
        <section className="Gallery buyNow">
            <div className='inner-banner'>
                <div className="container">
                    <h2>Gallery</h2>
                </div>
            </div>

            <div className="main About">
                <div className="container">
                    <div className="gallery_box">
                        <Formats />
                    </div>
                </div>
            </div>

        </section>
    );
}

export default Gallery;
