import React from 'react'

function Dashboard() {

  return (
    <div className='userdashboard'>
      User Dashboard
    </div>
  )
}

export default Dashboard