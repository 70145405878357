import React from 'react'

function CheckoutLayout({ page }) {
    
    return (
        <>
            {page}
        </>
    )
}

export default CheckoutLayout