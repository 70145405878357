import React, { useEffect, useRef, useState } from 'react';
import './ContactUs.css';
import "../../Components/Header/Header.css"
import { NavLink, useNavigate } from 'react-router-dom';
import Header from '../../Components/Header/Header';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Support from '../../Components/Support/Support';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import FeedBack from '../../Components/FeedBack/FeedBack';
import bgcoils from '../../Images/bgimg.png'
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import MarkEmailReadRoundedIcon from '@mui/icons-material/MarkEmailReadRounded';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SiteFooter from '../../Components/Footer/SiteFooter';
import { AiOutlineLink } from 'react-icons/ai';
import { IoChevronDown } from "react-icons/io5";

const ContactUs = () => {
  const [showContactSection, setShowContactSection] = useState(false);
  const contactSectionRef = useRef(null);

  // Function to scroll to the contact section
  const scrollToContactSection = () => {
    setShowContactSection(true); // Set the state to true to show the section
    setTimeout(() => {
      if (contactSectionRef.current) {
        contactSectionRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100); // Small delay to ensure section is rendered before scrolling
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const navigate = useNavigate()
  const [selectedForm, setSelectedForm] = useState('support');

  let title = '';
  if (selectedForm === 'support') {
    title = 'Get Technical Support';
  } else if (selectedForm === 'feedback') {
    title = 'How likely are you to recommend our service to your family or colleague';
  }


  const [activeIndex, setActiveIndex] = useState(0);
  const faqs = [
    { question: "Where do I find my purchased passes?", answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores, voluptas." },
    { question: "How to download purchased passes?", answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores, voluptas." },
    { question: "What is the last date for buying the seasonal passes?", answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores, voluptas." },
    { question: "How to avail discount?", answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores, voluptas." },
  ];

  useEffect(() => {
    // Set the height of the initially active FAQ answer
    const answers = document.querySelectorAll('.faq-ans');
    answers.forEach((answer, idx) => {
      if (idx === activeIndex) {
        answer.style.height = answer.scrollHeight + 'px';
      } else {
        answer.style.height = '0';
      }
    });
  }, [activeIndex]);

  const toggleFAQ = (index) => {
    if (activeIndex === index) {
      // Close the currently active FAQ smoothly
      const answer = document.querySelector(`.faq-ans[data-index="${index}"]`);
      answer.style.height = answer.scrollHeight + 'px'; // Set to current height for smooth transition
      setTimeout(() => {
        answer.style.height = ''; // Collapse after the height is set
      }, 300); // Match this to your CSS transition duration
      setActiveIndex(null); // Reset activeIndex to null
    } else {
      setActiveIndex(index); // Set the new active index
    }
  };

  return (
    <>

      <section className='contactUs buyNow'>
        <div className='inner-banner'>
          <div className="container">
            <h2>Support</h2>
          </div>
        </div>
      </section>

      <div className='Support-page'>
        <div className="content">
          <div className='container'>
            <section className='faqs-sec desktopFaq pt-0'>
              <div className='container'>
                <h2 className="and-fonts dy-nmobile">Frequently Asked Questions</h2>
                <div className="mobile-faq-container">
                  {faqs.map((faq, index) => (
                    <div
                      className={`faq-item ${activeIndex === index ? 'active' : ''}`}
                      key={index}
                      onClick={() => toggleFAQ(index)}
                    >
                      <div className="faq-question">
                        {faq.question}
                        <span className={`arrow ${activeIndex === index ? 'rotate' : ''}`}>
                          <IoChevronDown/>
                        </span>
                      </div>
                      <div className="faq-ans">
                        {faq.answer}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>

            {!showContactSection && (<section className='need_further_assistance'>
              <h2 className="and-fonts text-center">Need Further Assistance?</h2>
              <div className='main-heading pt-2 new-btn-style text-center pt-4'>
                <NavLink className="common-btn white cst-font-size gallry_filed og-font-style" onClick={scrollToContactSection}><span>Raise a ticket</span></NavLink>
              </div>
            </section>)}

            {showContactSection && (<div className='custom-wrapper-quote' ref={contactSectionRef}>
              <div className='inner-row-bdy'>
                <div className='about_blocks'>
                  <h1><span>C</span>OMMON <span>E</span>VENTS <span>P</span>LANNING <span>T</span>EAM
                  </h1>
                </div>
                <div className='box-details two'>

                  <ul className="elementor-icon-list-items">
                    {/* <li className="elementor-icon-list-item">
                      <a href="tel:+919326967938">
                        <span className="elementor-icon-list-icon">
                          <LocalPhoneRoundedIcon />
                        </span>
                        <span className="elementor-icon-list-text">+91-93269 67938</span>
                      </a>
                    </li> */}
                    <li className="elementor-icon-list-item">
                      <a href="mailto:commoneventsplanningteam@gmail.com">
                        <span className="elementor-icon-list-icon">
                          <MarkEmailReadRoundedIcon />
                        </span>
                        <span className="elementor-icon-list-text isEmail">commoneventsplanningteam@gmail.com</span>
                      </a>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <LocationOnIcon />
                      </span>
                      <span className="elementor-icon-list-text"><NavLink to="https://maps.app.goo.gl/N7oZgiUyqqa7tKtE9" target='_blank'>403, Satyamev Eminence, B/s.saptak Bungalows, Science City Road, Sola,ahmedabad-380060.<AiOutlineLink /></NavLink></span>
                    </li>
                  </ul>

                </div>
              </div>
              <div className='userdashboardcnt'>
                <div id="box1" className="box show">
                  <div className='Support-title mb-4'>
                    <h1>How can we help?</h1>
                  </div>
                  <div className='cst-radiobtns-bd'>
                    <Support />
                  </div>
                </div>
              </div>
            </div>)}
          </div>
        </div>

      </div>
    </>
  );
};

export default ContactUs;
