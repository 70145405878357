export const isValidEmail = (email) => {
    if (typeof email !== 'string') {
        return false;
    }

    email = email.trim().toLowerCase();

    var emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;

    return emailRegex.test(email);
};


export const isValidPhoneNumber = (phoneNumber) => {
    if (typeof phoneNumber !== 'string') {
        return false;
    }

    phoneNumber = phoneNumber.trim();

    var phoneRegex = /^\d{10}$/;

    return phoneRegex.test(phoneNumber);
};


export const validateRequiredFields = (data, requiredFields) => {
    const validationErrors = {};

    requiredFields.forEach((field) => {
        if (!data[field]) {
            validationErrors[field] = {
                message: `The ${field.replace("_", " ")} field is mandatory.`,
                rule: "required",
            };
        } else if (field === "email") {
            const emailValidation = isValidEmail(data[field]);
            if (!emailValidation) {
                validationErrors[field] = {
                    message: "please enter valid email address.",
                    rule: "email",
                };
            }
        } else if (field === "phone") {
            const phoneValidation = isValidPhoneNumber(data[field]);
            if (!phoneValidation) {
                validationErrors[field] = {
                    message: "please enter 10 digit phone number.",
                    rule: "phone",
                };
            }
        }
    });

    return validationErrors;
};


export const formatNumberWithCommas = (number) => {
    return number?.toLocaleString('en-IN');
};