import React, { useEffect } from 'react'
import './Profile.css'
import { useState } from 'react';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { NavLink, useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { Edit } from '@mui/icons-material';
import { Button } from '@mui/material';
import { getToken } from '../../Helper/Token';
import axios from 'axios';
import { getPatchUserDetails, getUserDetails } from '../../Api';
import 'bootstrap/dist/css/bootstrap.css';
import { BsChevronDown, BsTicketPerforated } from 'react-icons/bs';


function Profile() {
  const navigate = useNavigate()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  // Get User Details
  const [userDetails, setUserDetails] = useState({})
  const fetchUserDetails = () => {
    getToken();
    axios.patch(getPatchUserDetails)
      .then((res) => {
        setUserDetails({
          ...userDetails,
          ...res.data.user,
          TotalPass: res.data.TotalPass.length === 0 ? 0 : res.data.TotalPass[0].TotalPass
        });
      })
  }
  useEffect(() => {
    fetchUserDetails()
  }, [])

  // Display Profile Toggle
  const [isVisible, setIsVisible] = useState(false)
  const handleToggle = () => {
    if (isVisible) {
      setIsVisible(false)
    } else {
      setIsVisible(true)
    }
  }

  return (

    <>
      <div className='tabMenu profile'>
        <div className="mobileTab" onClick={() => handleToggle()}>
          <div className="left-text">
            <h4>Hi, {userDetails?.name}</h4>
            <NavLink className="EditBtn" to="/profile/setting" key="Setting" id="Setting"  >
              <div className="ussericon"><Edit /></div>
              <div className="user-link_text">Edit profile</div>
            </NavLink>
          </div>
          <div className={isVisible ? "open" : ""}>
            <BsChevronDown />
          </div>
        </div>
        <div className={`dashboard profile_page ${isVisible ? "show" : ""}`}>
          <div className="dashboard-right-header desktopOnly">
            <div className="left-text">
              <h4>Hi, {userDetails?.name}</h4>
            </div>
            <div>
              <Button className='edit_btn'>
                <NavLink className="userlink common-btn w-auto edit_btn'" to="/profile/setting" key="Setting" id="Setting"  >
                  <div className="ussericon"><Edit /></div>
                  <div className="user-link_text">Edit profile</div>
                </NavLink>
              </Button>
            </div>
          </div>

          <div className="userdash-row">
            <div className="dashboard-stats-box ">
              <div className="single-feature-box ">
                <div className="single-feature-icon">
                  <BsTicketPerforated />
                </div>
                <div className="single-feature-data">
                  <h6>{userDetails?.TotalPass}</h6>
                  <p>Total Tickets Bought</p>
                </div>
              </div>
            </div>
          </div>

          <div className="profileDetails">
            <div className="tital">
              <h5>Profile Information</h5>
            </div>

            <div className="detail">
              <div className="item w-100">
                <h6>Name </h6>
                <p>{userDetails?.name}</p>
              </div>
            </div>
          </div>

          <div className="profileDetails">
            <div className="tital">
              <h5>Contact details</h5>
            </div>

            <div className="detail">
              <div className="item">
                <h6>Email </h6>
                <p>{userDetails?.email}</p>
              </div>
              <div className="item">
                <h6>Phone </h6>
                <p>{userDetails?.phone}</p>
              </div>
              {
                userDetails.Address ?
                  <div className="item w-100">
                    <h6>Address </h6>
                    <p>{userDetails?.Address}, {userDetails?.City}, {userDetails?.Country}, {userDetails?.ZipCode}</p>
                  </div> : ""
              }
            </div>
          </div>

        </div>
      </div>
      <div className="mobileTab other" onClick={() => navigate('/orders')}>
        <div className="left-text">
          <h4>Orders</h4>
          <p>View your orders details</p>
        </div>
        <div className={isVisible ? "open" : ""}>
          <BsChevronDown />
        </div>
      </div>
    </>

  )
}

export default Profile